import swal from "sweetalert";

export function swalSuccess({ title, text } = {}) {
  return swal({
    title: title !== undefined ? title : "success!",
    text: text !== undefined ? text : "",
    icon: "success",
    button: "ok",
  });
}

export function swalError({ title, text } = {}) {
  return swal({
    title: title !== undefined ? title : "AN error occured!",
    text: text !== undefined ? text : "",
    icon: "error",
    button: "ok",
  });
}
