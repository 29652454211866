import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DateTH from '../../../Helper/date-th';

function ModalsHistory({ modalHis, setModalHis, historyData }) {
  return (
    <>
      <Modal
        show={modalHis}
        onHide={() => {
          setModalHis(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Scan history</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '15px' }}>
          <div style={{ overflow: 'auto' }}>
            <table className="table table-bordered">
              <thead style={{ backgroundColor: '#3f75c1' }}>
                <tr style={{ color: '#FFFFFF' }}>
                  <td style={{ width: '20%' }}>Date</td>
                  <td style={{ width: '10%' }}>Employee</td>
                  <td style={{ width: '10%' }}>Shipment status</td>
                </tr>
              </thead>
              <tbody>
                {historyData.map((value, index) => (
                  <tr key={index + 'Key' + value.id}>
                    <td>{value.created_date}</td>
                    <td>
                      {value.staff_code}<hr/>
                      {value.name}
                    </td>
                    <td>{value.shipment_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {historyData.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>ไม่พบข้อมูล</div> : ''}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalsHistory;
