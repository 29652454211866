import Instance from "../Helper/axios";
import { URLTEST, URL } from "../Helper/baseURL";

// api อัพโหลด label ด้วยไพล์ excel
export async function UploadFile(params) {
  try {
    let data = new FormData();
    data.append("custCode", params.custCode);
    data.append("sendType", params.sendType);
    data.append("files", params.file);
    const response = await Instance.post(URL + "manage/UploadFile/UploadFile", data);
    return await response.data;
  } catch (error) {}
}

// ดึงข้อมูลประวัติ upload file
export async function GetHistoryUpload(params) {
  try {
    const respons = await Instance.post(URL + "manage/UploadFile/getUploadFile", params);
    return await respons.data;
  } catch (error) {}
}

// ลบไพล์ upload
export async function RemoveFileUpload(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "manage/UploadFile/deleteData", data);
    return await respons.data;
  } catch (error) {}
}

// นำข้อมูลใน Excel เขาฐานข้อมูล
export async function InsertValueToDB(params,shipment_status) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    data.append("shipment_status", shipment_status);
    const respons = await Instance.post(URL + "manage/UploadFile/ImportDataFromFile", data);
    return respons.data;
  } catch (error) {}
}

// นำข้อมูลใน Excel เขาฐานข้อมูล
export async function InsertValueToDBNoDimension(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "manage/UploadFile/ImportDataFromFileNoDimension", data);
    return respons.data;
  } catch (error) {}
}
