import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";

export default function AdminMenu({ notification }) {
  const history = useHistory();

  return (
    <>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("MGBarcodes");
          }}
        >
          Manage Parcels
        </Nav.Link>
      </Nav.Item>
            <Nav.Item>
        <Nav.Link
          eventKey="/Parcel"
          onClick={(e) => {
            history.push("Parcel");
          }}
        >
          Create parcels
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          onClick={(e) => {
            history.push("ImportExcel");
          }}
        >
          Import Excel files
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <NavDropdown title="ReportStockIn" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("ReportStockIn");
            }}
          >
            ReportStockIn
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item> */}
      {/* <Nav.Item>
        <NavDropdown title="Shops" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("Shops");
            }}
          >
            Shop
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("Employees");
            }}
          >
            Employee
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("Size");
            }}
          >
            Size
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("FilterData");
            }}
          >
            FilterData
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("applyData");
            }}
          >
            ApplyData
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item> */}
      {/* <Nav.Item>
        <NavDropdown title="COD" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("historyCOD");
            }}
          >
            HistoryCOD
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("manageCOD");
            }}
          >
            ManageCOD
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("CallCar");
          }}
        >
          เรียกรถเข้ารับพัสดุ
          <b
            style={{
              position: "absolute",
              Zindex: "1",
              color: "#ffff",
              backgroundColor: "#f88e2a",
              width: "20px",
              border: "2px solid #ffffff",
              borderRadius: "50px",
              fontSize: "10px",
              marginRight: "50px",
            }}
            onClick={(e) => {
              history.push("CallCar");
            }}
          ><center>{notification}</center></b>
        </Nav.Link>
      </Nav.Item> */}
    </>
  );
}
