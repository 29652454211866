import React from "react";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportWithoutPictures = ({exportData2}) => {
    return (
        <ExcelFile
            element={
                <button type="button" style={{ display: 'none' }} id="ExportWithoutPicturesClick">
                    Download Data
                </button>
            }
            filename={'จัดการพัสดุ'}
        >
            <ExcelSheet data={exportData2} name={'จัดการพัสดุ'}>
                <ExcelColumn label="วันเปิดบิล" value="วันเปิดบิล" />
                <ExcelColumn label="รหัสลูกค้า" value="รหัสลูกค้า" />
                <ExcelColumn label="สำหรับลูกค้า / เลขที่co note" value="conote" />
                <ExcelColumn label="บาร์โค้ด/สำหรับลูกค้า" value="เลขลาเบล" />
                <ExcelColumn label="หมายเลขinvoice" value="invoice" />
                <ExcelColumn label="ผู้ส่ง" value="ผู้ส่ง" />
                <ExcelColumn label="ผู้รับ" value="ผู้รับ" />
                <ExcelColumn label="ผู้รับที่อยู่" value="ผู้รับที่อยู่" />
                <ExcelColumn label="อำเภอ" value="amphur" />
                <ExcelColumn label="จังหวัด" value="province" />
                <ExcelColumn label="จำนวน(ชิ้น)" value="จำนวน(ชิ้น)" />
                <ExcelColumn label="น้ำหนัก" value="weight" />
                <ExcelColumn label="ความกว้าง (ซม)" value="box_width" />
                <ExcelColumn label="ความยาว (ซม)" value="box_length" />
                <ExcelColumn label="ความสูง (ซม)" value="box_height" />
                <ExcelColumn label="ขนาด(ซม)" value="box_size" />
                <ExcelColumn label="ค่าขนส่ง" value="ค่าขนส่ง" />
                <ExcelColumn label="COD (บาท)" value="cod_cost" />
                <ExcelColumn label="ค่าบริการ COD" value="ค่าบริการCOD" />
                <ExcelColumn label="ยอดสุทธิ (บาท)" value="ยอดสุทธิ(บาท)" />
                <ExcelColumn label="ธนาคาร" value="bank_id" />
                <ExcelColumn label="ชื่อบัญชี" value="bank_name" />
                <ExcelColumn label="เลขที่บัญชี" value="bank_code" />
                <ExcelColumn label="สถานะ" value="สถานะ" />
                <ExcelColumn label="รหัสพนักงานขนส่ง" value="latest_scan" />
                <ExcelColumn label="ชื่อพนักงานขนส่ง" value="scan_name" />
                <ExcelColumn label="หมายเหตุ" value="หมายเหตุ" />
                <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
                <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
                <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
                <ExcelColumn label="จำนวนเอกสารนำกลับ" value="return_doc" />
                
            </ExcelSheet>
        </ExcelFile>
    )
};
