import React, { useState, useEffect } from "react";
import { TrackingV2Service } from "../../../Service/service.Tracking";
import ShowData from "./ShowData";
import ShowDataEN from "./ShowDataEN";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Icon from "react-bootstrap-icons";
import Schema from "./Validation";
import "../../../Themes/TackingTheme.css";
import { monthTH } from "../../../Data/month-th.json";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
// import AdList from "../../mis/Ads/features/adList";

function TrackingV2(props) {
  const { lang } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  var date = ("0" + new Date().getDate()).slice(-2);
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 1,
    last_page: 1,
    total: 0,
  });
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [noData, setNoData] = useState(0);
  const [count, setCount] = useState(0);

  async function onpageshow(){
    // document.getElementById('searchButton').click();  
    if(new URLSearchParams(window.location.search).has("search")){
      fetchData({
        tracking_number: new URLSearchParams(window.location.search).get("search"),
        start_date: "",
        end_date: "",
        pageNo: 1,
        perPage: 1,
        shipment_type: "",
        type: "0",
      });
    }

    setCount(1);
  }

  useEffect(() => {
    document.body.style.backgroundColor = "#C6D8EE";
    if(count===0) { 
      onpageshow();
    }
  });

  async function fetchData(value) {
    onLoad();
    const result = await TrackingV2Service(value);
    if (result.result.length > 0) {
      setData(result.result);
      setPage({
        current_page: result.current_page,
        last_page: result.last_page,
        total: result.total,
      });
      setNoData(0);
    } else {
      setData([]);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setNoData(1);
    }
    setLoading(0);
  }

  function onLoad() {
    setData([]);
    setNoData(0);
    setCurrentPage(1);
    setLoading(1);
    setPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
  }

  return (
    <>
      <Formik
        validationSchema={Schema}
        initialValues={{
          tracking_number: new URLSearchParams(window.location.search).has("search")?new URLSearchParams(window.location.search).get("search"):"",
          startDay: date,
          startMonth: month,
          startYear: year,
          endDay: date,
          endMonth: month,
          endYear: year,
          shipment_type: "",
          page: 1,
          type: "0",
        }}
        onSubmit={(value) => {
          fetchData({
            tracking_number: value.tracking_number,
            start_date: value.type === "1" ? value.startYear + "-" + value.startMonth + "-" + value.startDay : "",
            end_date: value.type === "1" ? value.endYear + "-" + value.endMonth + "-" + value.endDay : "",
            shipment_type: value.shipment_type,
            pageNo: 1,
            perPage: 1,
            type: value.type,
          });
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <div className="mt-5 mb-2">
              <div className="text-center text-front">
                <h1>{lang.language === "th" ? "追踪包裹" : "IMPORTATION EXPRESS"}</h1>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-sm-7">
                  <div>
                    <div className="mb-3 tabs-ct">
                      <center>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input radio-size"
                            type="radio"
                            name="type"
                            id="type0"
                            onClick={(e) => {
                              setFieldValue("type", "0");
                              setFieldValue("tracking_number", "");
                              setData([]);
                              setPage({
                                current_page: 1,
                                last_page: 1,
                                total: 0,
                              });
                            }}
                            value="0"
                            checked={values.type === "0" ? true : false}
                          />
                          <label className="form-check-label text-front" for="type0">
                          {lang.language === "th" ? "追踪号码" : "Recherche avec tracking number"}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input radio-size"
                            type="radio"
                            name="type"
                            id="type1"
                            onClick={(e) => {
                              setFieldValue("type", "1");
                              setFieldValue("tracking_number", "");
                              setData([]);
                              setPage({
                                current_page: 1,
                                last_page: 1,
                                total: 0,
                              });
                            }}
                            value="1"
                            checked={values.type === "1" ? true : false}
                          />
                          <label className="form-check-label text-front" for="type1">
                          {lang.language === "th" ? "带唛头的追踪" : "Recherche avec Shipping Mark"}
                            
                          </label>
                        </div>
                      </center>
                      <div className={"tab-contents-ct" + (values.type !== "0" ? "  hidden-ct " : " ")}>
                        <br />
                        <div className="row justify-content-lg-center">
                          <div className="col-lg-8">
                            <input
                              placeholder= {lang.language === "th" ? "Please input your tracking number" : "Please input your tracking number"}
                              value={values.tracking_number}
                              // value={new URLSearchParams(window.location.search).has("search")? new URLSearchParams(window.location.search).get("search"):values.tracking_number}
                              name="tracking_number"
                              className={
                                "form-control form-control-lg "+search +
                                (getIn(touched, "tracking_number")
                                  ? getIn(errors, "tracking_number")
                                    ? "is-invalid"
                                    : ""
                                  : "")
                              }
                              onChange={(e) => {
                                setSearch(e.target.value.trim());
                                setFieldValue("tracking_number", e.target.value.trim());
                              }}
                            />
                            <ErrorMessage component="div" name="tracking_number" className="invalid-feedback" />
                          </div>
                        </div>
                        <br />
                        <center>
                          <button type="submit" className="btn btn-primary-ct-front btn-lg" >

                            <Icon.Search />{lang.language === "th" ? "搜索" : "Recherche"} 
                          </button>
                          <span>&nbsp;</span>
                          <button
                            type="reset"
                            className="btn btn-secondary btn-lg"
                            onClick={() => {
                              setData([]);
                              setNoData(0);
                              setPage({
                                current_page: 1,
                                last_page: 1,
                                total: 0,
                              });
                            }}
                          >
                            <Icon.ArrowClockwise />
                          </button>
                        </center>
                        <br />
                      </div>
                      <div className={"tab-contents-ct" + (values.type !== "1" ? "  hidden-ct " : " ")}>
                        <br />
                        <div className="row justify-content-lg-center">
                          <div className="col-lg-8">
                          <label className="text-front">Shipping Mark</label>
                            <input
                              placeholder="please enter your Shipping Mark"
                              value={values.tracking_number}
                              name="tracking_number"
                              className={
                                "form-control form-control-lg " +
                                (getIn(touched, "tracking_number")
                                  ? getIn(errors, "tracking_number")
                                    ? "is-invalid"
                                    : ""
                                  : "")
                              }
                              onChange={(e) => {
                                setFieldValue("tracking_number", e.target.value);
                              }}
                            />
                            <ErrorMessage component="div" name="tracking_number" className="invalid-feedback" />
                          </div>
                          <div className=" col-lg-3">
                      <label className="text-front">Shipment type</label>
                      <select
                        value={values.shipment_status}
                        onChange={(e) => {
                          setFieldValue('shipment_type', e.target.value);
                        }}
                        className={'form-control form-control-lg dropdown-custom2 px-md-1 '}
                        placeholder="-- choose the Shipment type  --"
                      >
                        <option value={''}>all</option>
                        <option value={'0'}>1. SEA</option>
                        <option value={'1'}>2. ENVOI NORMAL</option>
                        <option value={'2'}>3. ENVOI NORMAL BATT</option>
                        <option value={'3'}>4. ENVOI EXPRESS</option>
                        <option value={'4'}>5. ENVOI EXPRESS BATT</option>
                      </select>
                    </div>
                        </div>
                        
                        <br />
                        <span className="text-front">Date de réception (Chine)</span>
                        <div class="row">
                          <div className="col-12 col-lg-6 px-md-4">
                            <span className="text-front">Début</span>
                            <div className="row">
                              <div className="col-12 col-sm-4 px-md-1 mt-1">
                                <select
                                  name="startYear"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "startYear") ? (getIn(errors, "startYear") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.startYear}
                                  onChange={(e) => {
                                    setFieldValue("startYear", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Year -- </option>
                                  {years.map((year, index) => (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="startYear" className="invalid-feedback" />
                              </div>
                              <div className="col-12 col-sm-4  px-md-1 mt-1">
                                <select
                                  name="startMonth"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "startMonth") ? (getIn(errors, "startMonth") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.startMonth}
                                  onChange={(e) => {
                                    setFieldValue("startMonth", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Month -- </option>
                                  {monthTH.map((item, index) => (
                                    <option value={item.id} key={item.id}>
                                      {item.monthName}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="startMonth" className="invalid-feedback" />
                              </div>
                              <div className="col-12 col-sm-4  px-md-1 mt-1">
                                <select
                                  name="startDay"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "startDay") ? (getIn(errors, "startDay") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.startDay}
                                  onChange={(e) => {
                                    setFieldValue("startDay", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Day -- </option>
                                  {Array.from(Array(31), (e, i) => (
                                    <option value={i + 1 < 10 ? "0" + (i + 1) : i + 1}>{i + 1}</option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="startDay" className="invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 px-md-4">
                            <span className="text-front">Fin</span>
                            <div className="row">
                              <div className="col-12 col-sm-4 px-md-1 mt-1">
                                <select
                                  name="endYear"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "endYear") ? (getIn(errors, "endYear") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.endYear}
                                  onChange={(e) => {
                                    setFieldValue("endYear", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Year -- </option>
                                  {years.map((year, index) => (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="endYear" className="invalid-feedback" />
                              </div>
                              <div className="col-12 col-sm-4  px-md-1 mt-1">
                                <select
                                  name="endMonth"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "endMonth") ? (getIn(errors, "endMonth") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.endMonth}
                                  onChange={(e) => {
                                    setFieldValue("endMonth", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Month -- </option>
                                  {monthTH.map((item, index) => (
                                    <option value={item.id} key={item.id}>
                                      {item.monthName}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="endMonth" className="invalid-feedback" />
                              </div>
                              <div className="col-12 col-sm-4  px-md-1 mt-1">
                                <select
                                  name="endDay"
                                  className={
                                    "form-control form-control-lg dropdown-custom2 px-md-1 " +
                                    (getIn(touched, "endDay") ? (getIn(errors, "endDay") ? "is-invalid" : "") : "") +
                                    ""
                                  }
                                  value={values.endDay}
                                  onChange={(e) => {
                                    setFieldValue("endDay", e.target.value);
                                  }}
                                >
                                  <option value=""> -- Day -- </option>
                                  {Array.from(Array(31), (e, i) => (
                                    <option value={i + 1 < 10 ? "0" + (i + 1) : i + 1} key={i}>
                                      {i + 1}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="div" name="endDay" className="invalid-feedback" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <center>
                          <button type="submit" className="btn btn-primary-ct-front btn-lg">
                            <Icon.Search /> Recherche
                          </button>
                          <span>&nbsp;</span>
                          <button
                            type="reset"
                            className="btn btn-secondary btn-lg"
                            onClick={() => {
                              setData([]);
                              setNoData(0);
                              setPage({
                                current_page: 1,
                                last_page: 1,
                                total: 0,
                              });
                            }}
                          >
                            <Icon.ArrowClockwise />
                          </button>
                        </center>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={loading === 1 && noData === 0 ? "text-center" : "hidden-ct"}>
                <div className="spinner-border text-light"></div>
                <span className="text-spinning-ct">&nbsp;{lang.language === "th" ? "...加载中" : "...Loading"} </span>
              </div>
              {lang.language === "th" ? <ShowDataEN data={data} /> : <ShowDataEN data={data} />}
              <div className={noData === 1? "text-center text-spinning-ct" : "hidden-ct"}>
              {lang.language === "th" ? "没有" : "Colis non reçu"}</div>
              <div className={page.total <= 1 ? "hidden-ct" : "d-flex justify-content-between container"}>
                <div style={{ color: "#110a27" }}> {lang.language === "th" ? "总数 " : "Total "}  {page.total} 
                {lang.language === "th" ? " 包裹" : " parcels"}</div>
                <div style={{ color: "#110a27" }}>
                  <Pagination
                    count={parseInt(page.last_page)}
                    page={currentPage}
                    onChange={(i, page) => {
                      fetchData({
                        tracking_number: values.tracking_number,
                        start_date: values.type === "1" ? values.startYear + "-" + values.startMonth + "-" + values.startDay : "",
                        end_date: values.type === "1" ? values.endYear + "-" + values.endMonth + "-" + values.endDay : "",
                        pageNo: page,
                        perPage: 1,
                        shipment_type: values.shipment_type,
                        type: values.type,
                      });
                      setCurrentPage(page);
                    }}
                    defaultPage={6}
                  />
                </div>
              </div>
              <br />
              <br />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(TrackingV2);
