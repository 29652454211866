import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Airplane, Ship } from 'iconsax-react';
import TrackingDetailEN from "./TrackingDetailEN";
import DateTH from "../../../Helper/date-thV2";

function ShowDataEn({ data }) {

  function Date(value,params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.created_date : '';
  }

  function step1(value) {
    let step = ( value.shipment_name === "RECEIVED IN CHINA WAREHOUSE" ||
      value.shipment_name === "IN TRANSIT" ||
      value.shipment_name === "ARRIVED IN MADAGASCAR" ||
      value.shipment_name === "RETURNED TO CHINA"
      ? " completed"
      : "")
    return step;
  }

  function step2(value) {
    let step = ( value.shipment_name === "IN TRANSIT" ||
    value.shipment_name === "ARRIVED IN MADAGASCAR" ||
    value.shipment_name === "RETURNED TO CHINA"
      ? " completed"
      : "")
    return step;
  }

  function step2Returned(value) {
    let step = ( value.shipment_name === "RETURNED TO CHINA"
     || value.shipment_name === "ARRIVED IN MADAGASCAR"
      ? " completed"
      : "")
    return step;
  }

  function step3(value) {
    let step = (value.shipment_name === "ARRIVED IN MADAGASCAR" ? " completed" : "")
    return step;
  }


  return (
    <>
      {data.map((value, index) => (
        <div className="container mb-3 " key={"detail" + index}>
          <div className="text-title-color-front">
            <h3>
              <Icon.Box /> # {value.tracking_number === "" || value.tracking_number === null ? 
              value.receiver_name : value.tracking_number}
            </h3>
          </div>
          <div className="row container text-front">
            <div className="col-lg-6">
              <b>Tracking number : </b>
              <span>{value.tracking_number}</span>
              <b> </b>
            </div>
            <div className="col-lg-6">
              <b>Poids : </b>
              <span>{value.weight} </span>
              <b> Kg </b>
            </div>
            <div className="col-lg-6">
              <b>Shipping mark : </b>
              <span>{value.receiver_name}</span>
            </div>
            <div className="col-lg-6">
              <b>Poids volumetrique : </b>
              <span>{(value.length*value.width*value.height/6000).toLocaleString(undefined, { minimumFractionDigits: 2 })} </span>
              <b> Kg </b>
            </div>
            <div className="col-lg-6">
              <b>Date de réception (Chine) :</b>
                {value.created_date.substring(0,10)} 
            </div>
            {value.type === '0' ? (
              <div className="col-lg-6">
                <b> Volume : </b>
                <span>{value.cbm} CBM</span>
              </div>
            ):(
              <div className="col-lg-6">
                <b> Volume : </b>
                <span>{(value.length*value.width*value.height/1000000).toLocaleString(undefined, { minimumFractionDigits: 2 })} CBM</span>
              </div>
            )}
            
            <div className="col-lg-6">
              <b>Type d'envoi : </b>
              <span>{value.type_name}</span>
            </div>
            <div className="col-lg-6">
              <b>Status : </b>
              <span>{value.shipment_name}</span>
            </div>
            <div className="col-lg-6">
                <b className="text-title-color-front">Date de départ: </b>
                {
                  value.stockin_date === "" || value.stockin_date === null ?
                  (<span>En attente d'envoi</span>) : value.stockin_date.substring(0,10)
                }
                 
            </div>
            {value.type === "0" ? 
            (
              <div className="col-lg-6">
                <b className="text-title-color-front">Prix de reference : </b>
                $ { (value.type === "0" && value.cbm >= 0.25) ? (value.cbm*430)
                : (value.cbm*450)}
                <p style={{color:'red'}}> *PS: Veuillez demander au bureau de vous donner la facture officielle </p>
            </div>
            ):"" }
            {value.type === "1" ? 
            (
              <div className="col-lg-6">
                <b>Tarif : </b> 67 500 Ariary / kilo <br/>
                <b className="text-title-color-front">Prix de reference : </b>
                Ar { ((value.length*value.width*value.height/6000) >= value.weight) ? 
                (parseInt((value.length*value.width*value.height/6000)*67500).toLocaleString().replace(",", " "))
                : (parseInt((value.weight*67500)).toLocaleString().replace(",", " ") )}
                <p style={{color:'red'}}> *PS: Veuillez demander au bureau de vous donner la facture officielle </p>
            </div>
            ):"" }
            {value.type === "2" ? 
            (
              <div className="col-lg-6">
                <b>Tarif : </b> 120 000 Ariary / kilo <br/>
                <b className="text-title-color-front">Prix de reference :  </b>
                Ar { ((value.length*value.width*value.height/6000) >= value.weight) ? (
                  parseInt((value.cbm*167)*120000).toLocaleString().replace(",", " "))
                : (parseInt(value.weight*120000).toLocaleString().replace(",", " "))}
                <p style={{color:'red'}}> *PS: Veuillez demander au bureau de vous donner la facture officielle </p>
            </div>
            ):"" }
            {value.type === "3" ? 
            (
              <div className="col-lg-6">
                <b>Tarif : </b> 82 500 Ariary / kilo <br/>
                <b className="text-title-color-front">Prix de reference : </b>
                Ar { ((value.length*value.width*value.height/6000)> value.weight) ? 
                parseInt((value.length*value.width*value.height/6000)*82500).toLocaleString().replace(",", " ")
                : parseInt((value.weight*82500)).toLocaleString().replace(",", " ")}
                <p style={{color:'red'}}> *PS: Veuillez demander au bureau de vous donner la facture officielle </p>
            </div>
            ):"" }
            {value.type === "4" ? 
            (
              <div className="col-lg-6">
                <b>Tarif : </b> 145 000 Ariary / kilo <br/>
                <b className="text-title-color-front">Prix de reference : </b>
                Ar { ((value.length*value.width*value.height/6000) > value.weight) ?
                 parseInt((value.length*value.width*value.height/6000)*145000).toLocaleString().replace(",", " ")
                : parseInt((value.weight*145000)).toLocaleString().replace(",", " ")} 
                <p style={{color:'red'}}> *PS: Veuillez demander au bureau de vous donner la facture officielle </p>
              </div>
            ):"" }
          </div>
          <hr style={{ borderBottom: "3px solid #110a27" }} />
          <br />
          <br />
          <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
            <div
              className={"step " + step1(value) + (value.shipment_name === "RECEIVED IN CHINA WAREHOUSE" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Clipboard size="70%" />
                </div>
              </div>
              <h4 className="step-title">REÇU ENTREPÔT CHINE</h4>
            </div>
            <div className={"line-ct" + step2(value)}>&nbsp;</div>
            <div
              className={"step " + step2(value) + (value.shipment_name === "IN TRANSIT" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                {value.type === '0' ? (<Ship size="70%" />):
                (<Airplane size="70%" />)}
                  
                </div>
              </div>
              <h4 className="step-title">EN TRANSIT</h4>
            </div>
            {value.shipment_name === "RETURNED TO CHINA" || Date(value,'RETURNED TO CHINA') !== '' ? (
              <div
              className={"step " + step2Returned(value) + (value.shipment_name === "RETURNED TO CHINA" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Person size="70%" />
                </div>
              </div>
              <h4 className="step-title">RETOURNÉ EN CHINE </h4>
            </div>    
            ) : "" }
           
            <div className={"line-ct" + step3(value)}>&nbsp;</div>
            <div className={"step " + step3(value) + (value.shipment_name === "ARRIVED IN MADAGASCAR" ? " now" : "")}  >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.House size="70%" />
                </div>
              </div>
              <h4 className="step-title">ARRIVÉ À MADAGASCAR</h4>
            </div>
          </div>
          <br />
          <TrackingDetailEN value={value} />
        </div>
      ))}
      <br />
    </>
  );
}

export default ShowDataEn;
