import React, {useEffect, useState} from 'react';
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import { nanoid } from 'nanoid';
import * as Icon from 'react-bootstrap-icons';
import InputAutocompleteV2 from '../../../Components/inputAutocompleteV2';
import { bank } from '../../../Data/bank.json';
import parcelSchema from './parcelValidate';
import FormReactSelect from '../../../Components/FormReactSelect';
import {calculatedPrice as calculatedPriceAPI} from "../../../Service/service.Parcels";
import {connect} from "react-redux";
import {correspondingSender} from "../../../Service/service.PorlorSender";
import Modal from 'react-bootstrap/Modal';

function Porlor({ SaveData,
                                 printLebel,
                                 loading,
                                 handleShow,
                                 setDataArray,
                                 setStatusPrint,
                                 checkCitizen,
                                 checkReceiver,
                                 checkReceiverMultiple,
                                 checkSize,
                                 dataCheck,
                                 user,
                                checkCitizenMultiple,
                                setShowModalCitizen,
                                showModalCitizen,
                                indexCitizen,
                                citizen,
                                valuesReady,
                                setValuesReady,
                                citizenList,
                                showModalReceiver,
                                setShowModalReceiver,
                                receiverList,
                                setReceiverList
                              }) {
  console.log('re rendering = ');
  const [indexReceiver,setIndexReceiver] = useState(0);
  const isCreditCustomer = localStorage.getItem('superkey') === 'credit';
  const isShop = localStorage.getItem('superkey') === 'shop';
  const isPublicUser = localStorage.getItem('superkey')  === '1947';
  const hideCost =localStorage.getItem('show_shipment_cost')  === '0';
  const cust_code = localStorage.getItem("username");
  let phone = "";
  if(localStorage.getItem('superkey') === '1947'){
       phone = localStorage.getItem("username");
  }
  else if(localStorage.getItem('superkey') === 'credit'){
       phone = localStorage.getItem('phone');
  }

  const initialValues = {
        sender: {
            ref_no: '',
            custCode: localStorage.getItem('username'),
            desc: '',
            phoneNumber:'',
        },
        recipient: [
            {
                key: nanoid(),
                ref_no: '',
                fullName: '',
                phoneNumber: '',
                desc: '',
                // homeNumber: '',
                type: '1',
                materialSizeWide: 0,
                materialSizeLong: 0,
                materialSizeHigh: 0,
                materialSize: 0,
                materialWeight: 0.1,
                cbm:0
            },
        ],
    };
  const [customInitialValues,setCustomInitialValues] = useState(initialValues);
  const [initiallyHasBank, setInitiallyHasBank] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const calculatedPrice = async (size, weight, cod, district) => {
    setPriceLoading(true);
    try {
      const result = await calculatedPriceAPI(size, weight, cod, district);
      return result;
    }
    catch (error) {
      throw error;
    }
    finally {
      setPriceLoading(false);
    }
  };

    return (
    <>
      <Formik
        validationSchema={parcelSchema}
        enableReinitialize={true}
        initialValues={customInitialValues}
        onSubmit={(value, { setFieldValue, resetForm }) => {
          // SaveData(value);
          value.recipient.forEach(row => row.ref_no = value.sender.ref_no);
          console.log(value);
          setDataArray(value);
          handleShow();
          resetForm();
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className={`card-supplies mb-3 px-md-1 ${isCreditCustomer?'hidden-ct':''}`}>
              <div className="card-body">
                <b className="mb-5 ">Create parcels</b>
                <div className="row mt-1">
                 
                  <div className="col-md-4 col-12  px-md-1 ">
                 
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FieldArray name="recipient">
                {({ push, remove }) =>
                  values.recipient.map((value, index) => (
                    <div className="col-12 col-md-12  mb-3" key={value.key}>
                      <div className="card-supplies">
                        <div className="card-body">
                          <div className="flex-space-between">
                            <b>
                              Please enter the informations here
                              {values.recipient.length === 1 ? ' ' : ' ' + (index + 1)}
                            </b>
                            <div>
                              <div className=" text-right">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    disabled={values.recipient.length === 1}
                                  >
                                    <Icon.TrashFill />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4 col-12 px-md-1 align-left ">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].ref_no`}>
                                    tracking number
                                  </label>
                                  <input
                                    type="text"
                                    className={'form-control'}
                                    aria-describedby={`recipient[${index}].ref_no`}
                                    name={`recipient[${index}].ref_no`}
                                    value={values.recipient[index].ref_no}
                                    onChange={(e) => {
                                      setFieldValue(`sender.ref_no`, e.target.value);
                                      setFieldValue(`recipient[${index}].ref_no`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage component="div" name={`recipient[${index}].ref_no`} className="invalid-feedback" />
                                </div>
                              </div>
                              <div className="col-md-4 col-12 px-md-1 align-left ">
                                <div className="form-group">
                                  <label htmlFor={`sender.desc`}>
                                    description
                                  </label>
                                  <input
                                    type="text"
                                    className={'form-control'}
                                    aria-describedby={`sender.desc`}
                                    name={`sender.desc`}
                                    value={values.sender.desc}
                                    onChange={(e) => {
                                      setFieldValue(`sender.desc`, e.target.value);
                                      setFieldValue(`recipient[${index}].desc`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage component="div" name={`sender.desc`} className="invalid-feedback" />
                                </div>
                              </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].fullName`}>recipient name</label>
                                <span className="important-ct">*</span>

                                <input
                                  // autocomplete="off"
                                  value={value.fullName}
                                  type="text"
                                  className={'form-control  ' +  (getIn(touched, `recipient[${index}].fullName`) ? (getIn(errors, `recipient[${index}].fullName`) ? 'is-invalid' : 'is-valid') : '')}
                                  aria-describedby={`recipient[${index}].fullName`}
                                  name={`recipient[${index}].fullName`}
                                  defaultValue={value.fullName}
                                  onChange={ async (e) => {
                                    const {value: fullname} = e.target;
                                    setFieldValue(`recipient[${index}].fullName`, e.target.value);
                                    if(fullname !== '' && fullname.match(/^[ก-๏a-zA-Z0-9./()_-\s]+$/)){
                                        setDisableSubmit(true);
                                        const result = await checkReceiverMultiple(fullname);
                                        if(Object.keys(result).length === 1){
                                            // setFieldValue(`recipient[${index}].homeNumber`, result[0].receiver_address);
                                            // setFieldValue(`recipient[${index}].receiver_address`, result[0].recipient_name);
                                            // setFieldValue(`recipient[${index}].district`, result[0].receiver_district);
                                            // setFieldValue(`recipient[${index}].province`, result[0].receiver_province);
                                            // setFieldValue(`recipient[${index}].zipcode`, result[0].receiver_zipCode);
                                            // setFieldValue(`recipient[${index}].amphoe`, result[0].receiver_amphur);
                                            setFieldValue(`recipient[${index}].phoneNumber`,result[0].receiver_phone);
                                            try{
                                                // var totalResult = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                                // const total = totalResult.data.price + totalResult.data.zipCode_fee;
                                                // setFieldValue(`recipient[${index}].total`, total);
                                                // setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                            }
                                            catch (e) {
                                              console.log(e);
                                            }
                                        }
                                        if(Object.keys(result).length > 1){
                                          setReceiverList(result);
                                          await setShowModalReceiver(true);
                                          
                                        }
                                        setDisableSubmit(false);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].fullName`} className="invalid-feedback" />
                              </div>
                            </div>

                            <div className="col-md-2 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].phoneNumber`}>Phone number</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.phoneNumber}
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].phoneNumber`) ? (getIn(errors, `recipient[${index}].phoneNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  aria-describedby={`recipient[${index}].phoneNumber`}
                                  name={`recipient[${index}].phoneNumber`}
                                  defaultValue={value.phoneNumber}
                                  onChange={async (e) => {
                                      const phoneNumber = e.target.value;
                                      setFieldValue(`recipient[${index}].phoneNumber`, phoneNumber);
                                      // if(phoneNumber.match(/^(?=[0-9]*$)(?:.{9,10}|.{30})$/)){
                                          setDisableSubmit(true);
                                          console.log('in condition');
                                          try{
                                              console.log('before checkReceiver');
                                              const result = await checkReceiverMultiple(phoneNumber);
                                              console.log('after checkReceiver');
                                              if(Object.keys(result).length === 1){
                                                console.log('another condition');
                                                setFieldValue(`recipient[${index}].fullName`, result[0].receiver_name);
                                                setDisableSubmit(false);
                                              }
                                              if(Object.keys(result).length > 1){
                                                setReceiverList(result);
                                                await setShowModalReceiver(true);
                                              }
                                            }
                                            catch (e) {
                                                console.log('error 2', e);
                                            }finally{
                                              setDisableSubmit(false);
                                            }

                                      // }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].phoneNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                           
                          </div>
                          <div className="row">
                          <div>
                              <div className="form-group checkbox-control mt-4" style={getIn(touched, `recipient[${index}].type`) ? (getIn(errors, `recipient[${index}].type`) ? { color: '#ee0808' } : { color: '#28a745' }) : { color: '#1a1818' }}>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].type1`}
                                  id={`recipient[${index}].type1`}
                                  className={'checkbox-custom '}
                                  checked={value.type === '1' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].type`, '1');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].type1`} className="mr-5">
                                  Normal Air
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].type2`}
                                  id={`recipient[${index}].type2`}
                                  className={'checkbox-custom '}
                                  checked={value.type === '2' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].type`, '2');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].type2`} className="mr-5">
                                  Normal BATT
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].type3`}
                                  id={`recipient[${index}].type3`}
                                  className={'checkbox-custom '}
                                  checked={value.type === '3' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].type`, '3');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].type3`} className="mr-5">
                                  Express
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].type4`}
                                  id={`recipient[${index}].type4`}
                                  className={'checkbox-custom '}
                                  checked={value.type === '4' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].type`, '4');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].type4`} className="mr-5">
                                  Express BATT
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].type0`}
                                  id={`recipient[${index}].type0`}
                                  // className="checkbox-custom"
                                  className={'checkbox-custom '}
                                  checked={value.type === '0' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].type`, '0');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].type0`} className="mr-5">
                                  Sea
                                </label>
                                <ErrorMessage component="div" name={`recipient[${index}].type`} className="invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          
                          <div className="row"></div>
                            <div className="row" style={{ justifyContent: 'left' }}>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}` }>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialWeight`}>Weight</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialWeight}
                                  type="number"
                                  name={`recipient[${index}].materialWeight`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialWeight`) ? (getIn(errors, `recipient[${index}].materialWeight`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialWeight`, e.target.value);
                                    if (e.target.value > 0) {
                                        setDisableSubmit(true);
                                        try{
                                            // var result = await calculatedPrice(value.materialSize, e.target.value, 0, value.amphoe);
                                            // const total = result.data.price + result.data.zipCode_fee;
                                            // setFieldValue(`recipient[${index}].total`, total);
                                            // setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                        }
                                        catch (e) {
                                          console.log(e);
                                        }
                                        setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialWeight`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}` }>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeWide`}>width</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeWide}
                                  type="number"
                                  name={`recipient[${index}].materialSizeWide`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeWide`) ? (getIn(errors, `recipient[${index}].materialSizeWide`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeWide`, e.target.value);
                                    if (e.target.value !== '' && value.materialSizeLong !== '' && value.materialSizeHigh !== '') {
                                      var sizeTotal = 0,size=0;
                                      sizeTotal = parseFloat(e.target.value) * parseFloat(value.materialSizeLong) * parseFloat(value.materialSizeHigh) * 0.000001;
                                      //poids volumetrique
                                      size = parseFloat(e.target.value) * parseFloat(value.materialSizeLong) * parseFloat(value.materialSizeHigh)/6000;
                                      setFieldValue(`recipient[${index}].cbm`, sizeTotal);
                                      setFieldValue(`recipient[${index}].materialSize`, size);
                                      setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeWide`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeLong`}>length</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeLong}
                                  type="number"
                                  name={`recipient[${index}].materialSizeLong`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeLong`) ? (getIn(errors, `recipient[${index}].materialSizeLong`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeLong`, e.target.value);
                                    var sizeTotal = 0,size=0;
                                      sizeTotal = parseFloat(e.target.value) * parseFloat(value.materialSizeWide) * parseFloat(value.materialSizeHigh)* 0.000001;
                                      //poids volumetrique
                                      size = parseFloat(e.target.value) * parseFloat(value.materialSizeWide) * parseFloat(value.materialSizeHigh)/6000;
                                      setFieldValue(`recipient[${index}].cbm`, sizeTotal);
                                      setFieldValue(`recipient[${index}].materialSize`, size);// if (e.target.value !== '' && value.materialSizeWide !== '' && value.materialSizeHigh !== '') {
                                    //     setDisableSubmit(true);
                                    //   var sizeTotal = 0;
                                    //   sizeTotal = parseFloat(e.target.value) + parseFloat(value.materialSizeWide) + parseFloat(value.materialSizeHigh);
                                    //   setFieldValue(`recipient[${index}].materialSize`, sizeTotal);
                                    //   try{
                                    //       var result = await calculatedPrice(sizeTotal, value.materialWeight, 0, value.amphoe);
                                    //       const total = result.data.price + result.data.zipCode_fee;
                                    //       setFieldValue(`recipient[${index}].total`, total);
                                    //       setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                    //   }
                                    //   catch(e){
                                    //     console.log(e);
                                    //   }
                                    //   setDisableSubmit(false);
                                    // }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeLong`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeHigh`}>Height</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeHigh}
                                  type="number"
                                  name={`recipient[${index}].materialSizeHigh`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeHigh`) ? (getIn(errors, `recipient[${index}].materialSizeHigh`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeHigh`, e.target.value);
                                    if (e.target.value !== '' && value.materialSizeWide !== '' && value.materialSizeLong !== '') {
                                        setDisableSubmit(true);
                                        var sizeTotal = 0,size=0;
                                      sizeTotal = parseFloat(e.target.value) * parseFloat(value.materialSizeLong) * parseFloat(value.materialSizeWide)* 0.000001;
                                      //poids volumetrique
                                      size = parseFloat(e.target.value) * parseFloat(value.materialSizeWide) * parseFloat(value.materialSizeLong)/6000;
                                      setFieldValue(`recipient[${index}].cbm`, sizeTotal);
                                      setFieldValue(`recipient[${index}].materialSize`, size);                                      // try{
                                      //     var result = await calculatedPrice(sizeTotal, value.materialWeight, 0, value.amphoe);
                                      //     const total = result.data.price + result.data.zipCode_fee;
                                      //     setFieldValue(`recipient[${index}].total`, total);
                                      //     setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                      // }
                                      // catch (e) {
                                      //   console.log(e);
                                      // }
                                      setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeHigh`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                            <div className="form-group">
                                <label htmlFor={`recipient[${index}].cbm`}>cbm</label>
                                <input value={value.cbm.toLocaleString(undefined, { minimumFractionDigits: 2 })+" cbm ("+value.materialSize.toLocaleString(undefined, { minimumFractionDigits: 2 })+" kg)"}
                                       readOnly={true}
                                       type="text"
                                       name={`recipient[${index}].materialSize`}
                                       className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSize`) ? (getIn(errors, `recipient[${index}].materialSize`) ? 'is-invalid' : 'is-valid') : '')}/>
                                <ErrorMessage component="div" name={`recipient[${index}].materialSize`} className="invalid-feedback" />
                              </div>
                            </div>
                            
                            {/* <div className={`col-md-2 ${isShop ? 'hidden-ct' : ''}`}>
                              <div className="form-group checkbox-control mt-4">
                                <input
                                  // autocomplete="off"
                                  type="checkbox"
                                  name={`recipient[${index}].materialCode`}
                                  id={`recipient[${index}].materialCode`}
                                  className={`checkbox-custom ${isShop ? 'hidden-ct' : ''}`}
                                  checked={value.materialCode === true ? true : false}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialCode`, e.target.checked);
                                    setFieldValue(`recipient[${index}].bankName`, values.sender.bankName);
                                    setFieldValue(`recipient[${index}].materialAccountName`, values.sender.materialAccountName);
                                    setFieldValue(`recipient[${index}].materialAccountNumber`, values.sender.materialAccountNumber);
                                    setFieldValue(`recipient[${index}].materialPriceCode`, '0');
                                    setFieldValue(`recipient[${index}].serviceCod`, 0);
                                    setDisableSubmit(true);
                                    try{
                                        var result = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                        const total = result.data.price + result.data.zipCode_fee;
                                        setFieldValue(`recipient[${index}].total`, total);
                                        setFieldValue(`recipient[${index}].totalNet`, total + 0);
                                    }
                                    catch (e) {
                                      console.log(e);
                                    }
                                      setDisableSubmit(false);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor="cod">COD (เก็บเงินปลายทาง)</label>
                              </div>
                            </div> */}
                            </div>
                          
                          <div className="row" style={{ justifyContent: 'left' }}>
                              <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                  {/* {!initiallyHasBank &&
                                      <>
                                        <label htmlFor={`recipient[${index}].bankName`}>ธนาคาร</label>
                                        <span className="important-ct">*</span>
                                        <FormReactSelect
                                          touched={touched}
                                          errors={errors}
                                          data={bank}
                                          values={value.bankName}
                                          onChange={(id) => {
                                            setFieldValue('sender.bankName',id);
                                            handleUpdateAllReceiversBanks({
                                                id,
                                            },values.recipient,setFieldValue);
                                          }}
                                          name={`recipient[${index}].bankName`}
                                          labelKey="bankName"
                                          keyValue="bank_id"
                                          errorsMess="กรุณาเลือกธนาคาร"
                                          placeholder="-- กรุณาเลือกธนาคาร --"
                                        />
                                      </>
                                  } */}
                                  {/* {initiallyHasBank && value.bankName && (
                                      <>
                                          <label htmlFor={`recipient[${index}].bankName`}>ธนาคาร</label>
                                          <input className="form-control" value={bank.find(row => row.bank_id === value.bankName).bankName} disabled={true} />
                                      </>
                                  )} */}
                              </div>
                           </div>
                           {/* <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountName">ชื่อบัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialAccountName}
                                  type="text"
                                  disabled={initiallyHasBank}
                                  name={`recipient[${index}].materialAccountName`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountName`) ? (getIn(errors, `recipient[${index}].materialAccountName`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`sender.materialAccountName`, e.target.value);
                                    handleUpdateAllReceiversBanks({
                                        name: e.target.value,
                                    },values.recipient,setFieldValue);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountName`} className="invalid-feedback" />
                              </div>
                            </div> */}
                            {/* <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountNumber">เลขที่บัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  disabled={initiallyHasBank}
                                  value={value.materialAccountNumber}
                                  type="text"
                                  name={`recipient[${index}].materialAccountNumber`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountNumber`) ? (getIn(errors, `recipient[${index}].materialAccountNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`sender.materialAccountNumber`, e.target.value);
                                    handleUpdateAllReceiversBanks({
                                        code: e.target.value,
                                    },values.recipient,setFieldValue);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountNumber`} className="invalid-feedback" />
                              </div>
                            </div> */}
                            {/* <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialPriceCode`}>จำนวนเงิน COD</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialPriceCode === '0' ? '' : value.materialPriceCode}
                                  type="number"
                                  name={`recipient[${index}].materialPriceCode`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialPriceCode`) ? (getIn(errors, `recipient[${index}].materialPriceCode`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].materialPriceCode`, e.target.value);
                                    var serviceCod = 0;
                                    serviceCod = Math.round((e.target.value / 100) * 3);
                                    if (serviceCod < 5) {
                                      serviceCod = 5;
                                    }
                                    setFieldValue(`recipient[${index}].serviceCod`, serviceCod);
                                    if (localStorage.getItem('requireDimension') === '0' && isCreditCustomer){
                                      setFieldValue(`recipient[${index}].totalNet`, serviceCod);
                                    }
                                    else{
                                      setFieldValue(`recipient[${index}].totalNet`, serviceCod + value.total);
                                    }
                                    
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialPriceCode`} className="invalid-feedback" />
                              </div>
                            </div> */}

                            {/* {hideCost && isCreditCustomer ? "":(
                              <div className={` col-md-2 col-12 px-md-1 ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].serviceCod`}>ค่าบริการ COD</label>
                                <input name={`recipient[${index}].serviceCod`} value={value.serviceCod.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                              </div>
                            </div>
                            )}
                            {hideCost && isCreditCustomer ? "":(
                              <div className={` col-md-2 col-12 px-md-1`}>
                                <label htmlFor={`recipient[${index}].total`}>ค่าขนส่ง</label>
                                <input name={`recipient[${index}].total`} value={value.total.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                            </div>
                            )}
                            {hideCost && isCreditCustomer ? "":(
                              <div className={`col-md-2 col-12 px-md-1 `}>
                                <label htmlFor={`recipient[${index}].totalNet`}>ยอดสุทธิ (บาท)</label>
                                <input name={`recipient[${index}].totalNet`} value={value.totalNet.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                            </div>
                              )}
                           </div> */}
                           {/* {isCreditCustomer && <div className="row" style={{ justifyContent: 'left' }}>
                              
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].ref_no2`}>เลขสำหรับลูกค้า 1</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].ref_no2`}
                                    name={`recipient[${index}].ref_no2`}
                                    value={values.recipient[index].ref_no2}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].ref_no2`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].item_desc`}>เลขสำหรับลูกค้า 2</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].item_desc`}
                                    name={`recipient[${index}].item_desc`}
                                    value={values.recipient[index].item_desc}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].item_desc`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                {/* </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].item_sku`}>เลขสำหรับลูกค้า 3</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].item_sku`}
                                    name={`recipient[${index}].item_sku`}
                                    value={values.recipient[index].item_sku}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].item_sku`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                {/* </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].invoice`}>หมายเลขinvoice</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].invoice`}
                                    name={`recipient[${index}].invoice`}
                                    value={values.recipient[index].invoice}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].invoice`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  /> */}
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                {/* </div>
                              </div>  */}
                              {/* <div className="col-md-2 px-md-1 hidden-ct">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].conote`}>conote</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].conote`}
                                    name={`recipient[${index}].conote`}
                                    value={values.recipient[index].conote}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].conote`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" />
                                </div> */}
                              </div> 
                          </div>                      
                        </div>
                      </div>
                  ))
                }
              </FieldArray>
            </div>
            <div className={"text-center mt-2 " + disableSubmit }>
              <button
                className="btn btn-success"
                type="submit"
                disabled={disableSubmit || priceLoading}
                onClick={() => {
                  console.log('errors = ', {
                      errors,
                      values,
                  });
                  setStatusPrint(0);
                }}
              >
                Create
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = (state) => ({
    user: state.Authentication,
});
export default connect(mapStateToProps)(Porlor);
