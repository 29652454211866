import React, { useState, useRef } from 'react';
import ShowData from './ShowData';
import { Formik, Form, ErrorMessage, getIn } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { monthTH } from '../../../Data/month-th.json';
import { shipment_status } from '../../../Data/shipment_status';
import { GetRecoveryBarcode, CustomerTypeService} from '../../../Service/service.Barcode';
import { Typeahead } from 'react-bootstrap-typeahead';
import Schema from './ValidateMgBarcode';
import {swalError} from "../../../Components/sweetAlert";
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import * as ExcelJS from "exceljs";
import { addImage } from '../../../utility/tableToExcel';
import { ExportProgress } from '../../../Components/ExportProgress/ExportProgress';
import { saveAs } from "file-saver";
import { ExportProgressStatus } from '../../../enums/ExportProgressStatus';

const getRecoveryCodeExportData = (params) => {
    return GetRecoveryBarcode({
      ...params,
      no_pagination: true,
    });
};

const getPaginatedExcelData = (params) => {
    return GetRecoveryBarcode({
      ...params,
      number_list: 240,
    });
};

const initialProgression = {
  total: 0,
  current: 0,
  totalPages: 0,
  currentPage: 0,
};


//TOUCH
function ManageBarcodeV2() {
  const history = useHistory();
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [loading, setLoading] = useState(0);
  const [notdata, setNotdata] = useState(0);
  const [pageSizes, setPageSizes] = useState(10);
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 1,
    last_page: 1,
    total: 0,
  });
  const [sum, setSum] = useState({
    cod_cost: 0,
    shipment_cost: 0,
  });
  const [dataSelect, setDataSelect] = useState([]);
  const [printLabel, setPrintLabel] = useState([]);
  const [printLabelSingle, setPrintLabelSingle] = useState({});
  const [exportData, setExportData] = useState(null);
  const [exportData2, setExportData2] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [currentPicExportFilters, setCurrentPickExportFilters] = useState(null);
  const excelObjectRef = useRef(null);

  const [exportProgression, setExportProgression] = useState(initialProgression);

  useEffect(() => {
    if(localStorage.getItem("superkey")==="shop" 
    || localStorage.getItem("superkey")==="credit"
    || localStorage.getItem("superkey")==="1947" 
    || !localStorage.getItem("superkey")){
      // history.push("/notAvailable");
      console.log("tsy admin")
      console.log(localStorage.getItem("superkey"))
    }
  }, []);

  async function CustomerType(data) {
    const result = await CustomerTypeService(data);
    if (data !== '') {
      setCustomers(result.data);
    }
  }

  async function fetchData(value) {
    console.log('fetch data here ? ')
    setLoading(1);
    setNotdata(0);
    const res = await GetRecoveryBarcode(value);
    if (res !== false) {
      setData(res.data.result);
      setPage({
        current_page: res.data.pageNo,
        last_page: res.data.maxPage,
        total: res.data.numRowsAll,
      });
      setSum({
        weight: res.sum.weight,
        cbm: res.sum.cbm,
        cod_cost: res.sum.cod_cost,
        shipment_cost: res.sum.shipment_cost,
      });
    } else {
      setNotdata(1);
      setData([]);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setSum({
        cod_cost: 0,
        shipment_cost: 0,
      });
    }
    setLoading(0);
  }

  async function fetchExportData(params) {
    setIsExporting(true);
    setCurrentPickExportFilters(params);
    handleExportDataServerFetch({
      ...params,
      page: 1,
    });
  }

  async function handleExportDataServerFetch(params) {
    try {
      const res = await getPaginatedExcelData(params);
      const serverResults = res.data.result.map(value => ({
            'วันเปิดบิล':value.created_date.substring(0, 10),
            'รหัสลูกค้า':value.cust_code,
            'conote': value.ref_no === null || value.ref_no === '' || value.ref_no === "0"  ? "" : value.ref_no,
            'เลขลาเบล':value.awb_code,
            'ผู้ส่ง':value.sender_name,
            'ผู้รับ':value.receiver_name,
            'ผู้รับที่อยู่': value.receiver_address,
            'district': value.receiver_district,
            'amphur': value.receiver_amphur,
            'province': value.receiver_province,
            'เบอร์โทรผู้รับ': value.receiver_phone,
            'จำนวน(ชิ้น)': value.num_parcel,
            'ค่าขนส่ง(บาท)': (parseFloat(value.shipment_cost) + parseFloat(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'COD': parseFloat(value.cod_cost),
            'สถานะ': value.shipment_name,
            'weight': value.weight,
            'box_size': value.box_size,
            'box_width': value.box_width,
            'box_length': value.box_length,
            'box_height': value.box_height,
            'ชื่อธนาคาร': value.bank_id,
            'ชื่อบัญชี': value.bookbank_name, 
            'เลขที่บัญชี': value.bookbank_code,
            'หมายเหตุ': value.remarkMG,
            'item_sku': value.item_sku,
            'item_desc': value.item_desc,
            'invoice': value.invoice,
            'ref_no2': value.ref_no2,
            'return_doc': value.num_return_doc,
            'receiver_date': value.receiver_date,
            'latest_scan': value.transportWorker,
            'scan_name': value.scanner_name,
            'image': value.image,
      }));
      setExportData({
        data: serverResults,  
        page: params.page,  
        lastPage: res.data.maxPage,  
      });
      setExportProgression((state) => ({
        ...state,
        current: 0,
        totalPages: res.data.maxPage,
        total: res.data.result.length,
      }));
    }
    catch (error) {
      console.log(error);
      setIsExporting(false);
      excelObjectRef.current = null;
        swalError({title: '', text: 'เกิดข้อผิดพลาดระหว่างการส่งออก'})
            .then(value => {
                setIsExporting(false);
        });
    }
  }

  async function fetchExportData2(params){
    const res2 = await getRecoveryCodeExportData(params);
      const exportData2 = res2.data.map(value => ({
        'วันเปิดบิล':value.created_date.substring(0, 10),
        'รหัสลูกค้า':value.cust_code,
        'conote': value.ref_no === null || value.ref_no === '' || value.ref_no === "0"  ? "" : value.ref_no,
        'ref_no2': value.ref_no2,
        'เลขลาเบล':value.awb_code,
        'ผู้ส่ง':value.sender_name,
        'ผู้รับ':value.receiver_name,
        'ผู้รับที่อยู่': value.receiver_address,
        'district': value.receiver_district,
        'amphur': value.receiver_amphur,
        'province': value.receiver_province,
        'จำนวน(ชิ้น)': value.num_parcel,
        'ค่าขนส่ง':parseFloat(value.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 }),
        'cod_cost' : parseFloat(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 }),
        'ค่าบริการCOD': parseFloat(value.service_cod).toLocaleString(undefined, { minimumFractionDigits: 2 }),
        'ยอดสุทธิ(บาท)': (parseFloat(value.shipment_cost) + parseFloat(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 }),
        'bank_id' : value.bank_id,
        'bank_name' : value.bookbank_name,
        'bank_code' : value.bookbank_code,
        'สถานะ': value.shipment_name,
        'latest_scan': value.transportWorker,
        'scan_name': value.scanner_name,
        'หมายเหตุ': value.remarkMG,
        'item_sku': value.item_sku,
        'item_desc': value.item_desc,
        'invoice': value.invoice,
        'return_doc': value.num_return_doc,
        'weight': value.weight,
        'box_size': value.box_size,
        'box_width': value.box_width,
        'box_length': value.box_length,
        'box_height': value.box_height,
      }));
      setExportData2(exportData2);
      setTimeout(() => {
        document.getElementById("ExportWithoutPicturesClick").click();
      }, 0)
  }

  const handleImageFetch = (workbook,worksheet) => {
          const promiseArray = [];
          let progression = 0;
          console.log({
            data: exportData.data,
          });
          exportData.data.forEach((data, index) => {
            const row = worksheet.addRow({
              'วันเปิดบิล': data['วันเปิดบิล'],
              'รหัสลูกค้า': data['รหัสลูกค้า'],
              'เลขลาเบล': data['เลขลาเบล'],
              'เลขที่conote': data['conote'],
              'ผู้ส่ง': data['ผู้ส่ง'],
              'ผู้รับ': data['ผู้รับ'],
              'เบอร์โทรผู้รับ': data["เบอร์โทรผู้รับ"],
              'ที่อยู่': data["ผู้รับที่อยู่"], 
              'ตำบล' : data["district"], 
              'อำเภอ' : data["amphur"], 
              'จังหวัด': data["province"],
              'ผู้รับที่อยู่': data['ผู้รับที่อยู่'],
              'น้ำหนัก': data['weight'],
              'ความกว้าง (ซม.)': data['box_width'],
              'ความยาว (ซม.)': data['box_length'], 
              'ความสูง (ซม.)': data['box_height'], 
              'ขนาด(ซ.ม.)': data['box_size'],
              'จำนวน(ชิ้น)': data['จำนวน(ชิ้น)'],
              'COD (บาท)': data['COD'],
              'ค่าขนส่ง(บาท)': data['ค่าขนส่ง(บาท)'],
              'ชื่อธนาคาร' : data['ชื่อธนาคาร'],
              'ชื่อบัญชี' : data['ชื่อบัญชี'],
              'เลขที่บัญชี' : data['เลขที่บัญชี'],  
              'เลขสำหรับลูกค้า 1' : data['ref_no2'],
              'เลขสำหรับลูกค้า 2' : data['item_desc'],
              'เลขสำหรับลูกค้า 3': data['item_sku'],
              'จำนวนเอกสารนำกลับ' : data['num_return_doc'],
              'สถานะ': data['สถานะ'],
              'วันที่เซ็นรับ' : data['receiver_date'],
              'รหัสพนักงานขนส่ง': data['latest_scan'],
              'ชื่อพนักงานขนส่ง': data['scan_name'],
            });
            if(data['image'] !== '' && data['image'] !== undefined){
                const imagePromise = addImage(data['image'], workbook, worksheet,30,row);
                imagePromise.then(image => {
                    progression++;
                    setExportProgression((state) => ({
                        ...state,
                        current: progression
                    }));
                });
                imagePromise.catch(error => {
                    console.error(error);
                  progression++;
                  setExportProgression((state) => ({
                        ...state,
                        current: progression
                  }));
                });
                promiseArray.push(
                    imagePromise
                );
            }
            else{
                    progression++;
                    setExportProgression((state) => ({
                        ...state,
                        current: progression
                    }));
                }
          });
          return Promise.all(promiseArray);
  };
  useEffect(() => {
    ( async () => {
    if (exportData) {
        if (!excelObjectRef.current) {
          const workBook = new ExcelJS.Workbook();
          const worksheet = workBook.addWorksheet('จัดการพัสดุ');
          excelObjectRef.current = {
              workBook,
              worksheet,
          };
          worksheet.columns = [
            { header: 'วันเปิดบิล', key: 'วันเปิดบิล', width: 10 },
            { header: 'รหัสลูกค้า', key: 'รหัสลูกค้า', width: 20 },
            { header: 'เลขลาเบล', key: 'เลขลาเบล', width: 50 },
            { header: 'สำหรับลูกค้า / เลขที่co note', key: 'เลขที่conote', width: 50 },
            { header: 'ผู้ส่ง', key: 'ผู้ส่ง', width: 50 },
            { header: 'ผู้รับ', key: 'ผู้รับ', width: 50 },
            { header: 'จำนวน(ชิ้น)', key: 'จำนวน(ชิ้น)', width: 50 },
            { header: 'เบอร์โทรผู้รับ', key: 'เบอร์โทรผู้รับ', width: 50 },
            { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
            { header: 'ตำบล', key: 'ตำบล', width: 50 },
            { header: 'อำเภอ', key: 'อำเภอ', width: 50 },
            { header: 'จังหวัด', key: 'จังหวัด', width: 50 },
            // { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
            { header: 'น้ำหนัก', key: 'น้ำหนัก', width: 50 },
            { header: 'ความกว้าง (ซม.)', key: 'ความกว้าง (ซม.)', width: 50 },
            { header: 'ความยาว (ซม.)', key: 'ความยาว (ซม.)', width: 50 },
            { header: 'ความสูง (ซม.)', key: 'ความสูง (ซม.)', width: 50 },
            { header: 'ขนาด(ซ.ม.)', key: 'ขนาด(ซ.ม.)', width: 50 }, 
            { header: 'COD (บาท)', key: 'COD (บาท)', width: 50 },
            { header: 'ค่าขนส่ง(บาท)', key: 'ค่าขนส่ง(บาท)', width: 50 },
            { header: 'ชื่อธนาคาร', key: 'ชื่อธนาคาร', width: 50 },
            { header: 'ชื่อบัญชี', key: 'ชื่อบัญชี', width: 50 },
            { header: 'เลขที่บัญชี', key: 'เลขที่บัญชี', width: 50 },
            { header: 'เลขสำหรับลูกค้า 1', key: 'เลขสำหรับลูกค้า 1', width: 50 },
            { header: 'เลขสำหรับลูกค้า 2', key: 'เลขสำหรับลูกค้า 2', width: 50 },
            { header: 'เลขสำหรับลูกค้า 3', key: 'เลขสำหรับลูกค้า 3', width: 50 },
            { header: 'จำนวนเอกสารนำกลับ', key: 'จำนวนเอกสารนำกลับ', width: 50 },
            { header: 'สถานะ', key: 'สถานะ', width: 50 },
            { header: 'รหัสพนักงานขนส่ง', key: 'รหัสพนักงานขนส่ง', width: 50 },
            { header: 'ชื่อพนักงานขนส่ง', key: 'ชื่อพนักงานขนส่ง', width: 50 },
            { header: 'วันที่เซ็นรับ', key: 'วันที่เซ็นรับ', width: 50 },
            { header: 'ลายเซ็น', key: 'ลายเซ็น', width: 125 },
          ];
        }

        await handleImageFetch(excelObjectRef.current.workBook,excelObjectRef.current.worksheet);
        setExportProgression((state) => ({
            ...state,
            currentPage: state.currentPage + 1,
        }));
        if (exportData.page !== exportData.lastPage) {
          handleExportDataServerFetch({
            ...currentPicExportFilters,
            page: exportData.page + 1,
          });
        }
        else {
            setExportProgression((state) => ({
              ...state,
              currentPage: state.totalPages,
            }));
            const result = await excelObjectRef.current.workBook.xlsx.writeBuffer();
            const blob = new Blob([result], { type: "application/xlsx" });
            setExportData(null);
            saveAs(blob, `จัดการพัสดุ.xlsx`);
            setIsExporting(false);
            setExportData(initialProgression);
        }
    }
    })();
  }, [exportData]);

  
  const getStatus = () => {
    if(!exportData){
      return ExportProgressStatus.DATA_DOWNLOADING;
    }
    return ExportProgressStatus.PROCESSING_GENERATION;
  };
  return (
    <>
      {isExporting && <ExportProgress status={getStatus()} progression={exportProgression} />}
      <Formik
        validationSchema={Schema}
        initialValues={{
          customerType: '',
          brand: '',
          search: '',
          shipment_status: '',
          dateNow: 0,
          startDay: '',
          startMonth: '',
          startYear: '',
          endDay: '',
          endMonth: '',
          endYear: '',
          cod_status: '',
        }}
        enableReinitialize={true}
        onSubmit={(value) => {
          fetchData({
            sendType: value.customerType,
            search: value.search,
            shipment_status: value.shipment_status,
            start_date: value.startYear !== '' && value.startMonth !== '' && value.startDay !== '' ? value.startYear + '-' + value.startMonth + '-' + value.startDay : '',
            end_date: value.endYear !== '' && value.endMonth !== '' && value.endDay !== '' ? value.endYear + '-' + value.endMonth + '-' + value.endDay : '',
            page: 1,
            number_list: pageSizes,
            cust_code: value.brand,
            cod_status: value.cod_status,
            show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
          });
          setDataSelect([]);
          setPrintLabel([]);
          setCurrentPage(1);
          setPage({
            current_page: 1,
            last_page: 1,
            total: 0,
          });
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>Manage</h2>
                  </div>
                  <br />
                  <div className="form-row justify-content-md-center">
                    <div className="form-group col-sm-2 px-md-1 mt-1">
                      <label>by</label>
                      <select
                        value={values.customerType}
                        onChange={(e) => {
                          // CustomerType({ customer_type: e.target.value });
                          setFieldValue('customerType', e.target.value);
                        }}
                        className="form-control dropdown-custom"
                      >
                        <option value={''}>-- all --</option>
                        <option value={'0'}>Sea</option>
                        <option value={'1'}>Normal Air</option>
                        <option value={'2'}>Air battery</option>
                        <option value={'3'}>Express</option>
                        <option value={'4'}>Express BAT</option>
                      </select>
                    </div>
                    <div className="form-group col-sm-3 px-md-1 mt-1">
                      <label>File name</label>
                      <Typeahead
                        defaultSelected={customers.filter((a) => a.cust_code === values.brand)}
                        id="brand"
                        name="brand"
                        labelKey={(option) => `${option.cust_code} ${option.name}`}
                        onChange={(e) => {
                          if (e.length > 0) {
                            let value = e[0].cust_code;
                            setFieldValue('brand', value);
                          }
                        }}
                        onInputChange={(text, e) => {
                          setFieldValue('brand', '');
                        }}
                        options={customers}
                        placeholder="-- ex: IHAS001 --"
                        selected={values.brand === '' ? [] : customers.filter((a) => a.cust_code === values.brand)}
                      />
                    </div>
                    <div className="form-group col-sm-5 px-md-1 mt-1">
                      <label>search,tracking number,description,recipient name</label>
                      <input
                        type="text"
                        value={values.search}
                        onChange={(e) => {
                          setFieldValue('search', e.target.value);
                        }}
                        placeholder="search by tracking number"
                        className={'form-control '}
                      />
                    </div>
                    <div className="form-group col-sm-2 px-md-1 mt-1">
                      <label>shipment status</label>
                      <select
                        value={values.shipment_status}
                        onChange={(e) => {
                          setFieldValue('shipment_status', e.target.value);
                        }}
                        className={'form-control'}
                        placeholder="-- choose the shipment status  --"
                      >
                        <option value={''}>all</option>
                        <option value={'C'}>C. cancelled</option>
                        {shipment_status.map((item, index) => (
                          <option value={item.id} key={index}>
                            {index  + '. ' + item.StatusName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row justify-content-md-center">
                    <div className="form-group col-12 col-lg-2">
                      <br />
                      <center style={{ marginTop: '10px' }}>
                        <div className="form-check">
                          <input
                            checked={values.dateNow === 0 ? false : true}
                            style={{ width: '15px', height: '15px' }}
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={(e) => {
                              if (values.dateNow === 0) {
                                setFieldValue('dateNow', 1);
                                setFieldValue('startDay', date);
                                setFieldValue('startMonth', ('0' + month).slice(-2));
                                setFieldValue('startYear', year);
                                setFieldValue('endDay', date);
                                setFieldValue('endMonth', ('0' + month).slice(-2));
                                setFieldValue('endYear', year);
                              } else {
                                setFieldValue('dateNow', 0);
                                setFieldValue('startDay', '');
                                setFieldValue('startMonth', '');
                                setFieldValue('startYear', '');
                                setFieldValue('endDay', '');
                                setFieldValue('endMonth', '');
                                setFieldValue('endYear', '');
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: '10px' }}>
                            today
                          </label>
                        </div>
                      </center>
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <span>Start date</span>
                      <div className="form-row">
                        <div className="form-group col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                          <select
                            name="startYear"
                            className={'form-control ' + (getIn(touched, 'startYear') ? (getIn(errors, 'startYear') ? 'is-invalid' : '') : '')}
                            value={values.startYear}
                            onChange={(e) => {
                              setFieldValue('startYear', e.target.value);
                            }}
                          >
                            <option value=""> -- year -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="startYear" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                          <select
                            name="startMonth"
                            className={'form-control ' + (getIn(touched, 'startMonth') ? (getIn(errors, 'startMonth') ? 'is-invalid' : '') : '')}
                            value={values.startMonth}
                            onChange={(e) => {
                              setFieldValue('startMonth', e.target.value);
                            }}
                          >
                            <option value=""> -- month -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="startMonth" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                          <select
                            name="startDay"
                            className={'form-control ' + (getIn(touched, 'startDay') ? (getIn(errors, 'startDay') ? 'is-invalid' : '') : '')}
                            value={values.startDay}
                            onChange={(e) => {
                              setFieldValue('startDay', e.target.value);
                            }}
                          >
                            <option value=""> -- day -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1}>{i + 1}</option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="startDay" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <span>end date</span>
                      <div className="form-row">
                        <div className="form-group col-12 col-sm-4 px-md-1 mt-1">
                          <select
                            name="endYear"
                            className={'form-control ' + (getIn(touched, 'endYear') ? (getIn(errors, 'endYear') ? 'is-invalid' : '') : '')}
                            value={values.endYear}
                            onChange={(e) => {
                              setFieldValue('endYear', e.target.value);
                            }}
                          >
                            <option value=""> -- year -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="endYear" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            name="endMonth"
                            className={'form-control ' + (getIn(touched, 'endMonth') ? (getIn(errors, 'endMonth') ? 'is-invalid' : '') : '')}
                            value={values.endMonth}
                            onChange={(e) => {
                              setFieldValue('endMonth', e.target.value);
                            }}
                          >
                            <option value=""> -- month -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="endMonth" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            name="endDay"
                            className={'form-control ' + (getIn(touched, 'endDay') ? (getIn(errors, 'endDay') ? 'is-invalid' : '') : '')}
                            value={values.endDay}
                            onChange={(e) => {
                              setFieldValue('endDay', e.target.value);
                            }}
                          >
                            <option value=""> -- day -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1} key={i}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage component="div" name="endDay" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-lg px-md-1 mt-0">
                      <br />
                      <center style={{ marginTop: '10px' }}>
                        {/* <div className="form-check form-check-inline">
                          <input
                            checked={values.cod_status === '1' ? true : false}
                            style={{ width: '15px', height: '15px' }}
                            className="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="1"
                            onChange={(e) => {
                              if (values.cod_status !== '1' ? true : false) {
                                setFieldValue('cod_status', e.target.value);
                              } else {
                                setFieldValue('cod_status', '');
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            Paid
                          </label>
                        </div> */}
                        {/* <div className="form-check form-check-inline">
                          <input
                            checked={values.cod_status === '0' ? true : false}
                            style={{ width: '15px', height: '15px' }}
                            className="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="0"
                            onChange={(e) => {
                              if (values.cod_status !== '0' ? true : false) {
                                setFieldValue('cod_status', e.target.value);
                              } else {
                                setFieldValue('cod_status', '');
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            Not Paid
                          </label>
                        </div> */}
                      </center>
                    </div>
                  </div>
                  <br />
                  <div className="form-row justify-content-md-center">
                    <div>
                      <button disabled={loading === 1 ? true : false} type="submit" className={loading === 1 ? 'btn btn-secondary' : 'btn btn-primary'}>
                        <Icon.Search /> search
                      </button>
                      <span>&nbsp;</span>
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          setNotdata(0);
                          setData([]);
                          setCurrentPage(1);
                          setPageSizes(10);
                          setPage({
                            current_page: 1,
                            last_page: 1,
                            total: 0,
                          });
                          setSum({
                            cod_cost: 0,
                            shipment_cost: 0,
                          });
                          setDataSelect([]);
                          setPrintLabel([]);
                          setPrintLabelSingle({});
                        }}
                      >
                        <Icon.ArrowClockwise /> clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <-------------------------------------------------------แสดงข้อมูล---------------------------------------------> */}
            <div className={data.length > 0 && loading === 0 ? 'mb-5' : 'hidden-ct'}>
              <ShowData
                  data={data}
                  setData={setData}
                  setPageSizes={setPageSizes}
                  pageSizes={pageSizes}
                  values={values}
                  fetchData={fetchData}
                  page={page}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  sum={sum}
                  dataSelect={dataSelect}
                  setDataSelect={setDataSelect}
                  printLabel={printLabel}
                  setPrintLabel={setPrintLabel}
                  printLabelSingle={printLabelSingle}
                  setPrintLabelSingle={setPrintLabelSingle}
                  exportData={exportData}
                  exportData2={exportData2}
                  clearExportData={() => {
                      setExportData(null);
                      setIsExporting(false);
                  }}
                  isExporting={isExporting}
                  fetchExportData={fetchExportData}
                  fetchExportData2={fetchExportData2} />
            </div>
            <div className={data.length === 0 && notdata === 1 ? 'm-2' : 'hidden-ct'}>
              <div className="text-center">
                <font color="red" size="4">
                  <br />
                  No information found!
                  <br />
                </font>
              </div>
            </div>
            <div className={loading === 1 ? 'm-2' : 'hidden-ct'}>
              <div className="text-center">
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {' ...Loading data'}
              </div>
            </div>
            {/* <-------------------------------------------------------แสดงข้อมูล---------------------------------------------> */}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ManageBarcodeV2;
