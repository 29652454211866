import misLayout from "../Layouts/mis/misLayout";
import LoginLayout from "../Layouts/mis/LoginLayout";
import PublicLayout from "../Layouts/Public/PublicLayout";
// import Tracking from "../Views/public/tracking/Tracking";
import Tracking from "../Views/public/trackingV2/TrackingV2";
// import MGBarcodes from "../Views/mis/manageBarcodes";
import MGBarcodes from "../Views/mis/manageBarcodesV2/ManageBarcodeV2";
import Login from "../Views/mis/login";
import Parcel from "../Views/mis/Parcel/parcel"; 
import Error from "../Views/error/error";
import ImportExcel from "../Views/mis/importLabel/ImportExcel/importExcel";
import ImportExcelOP from "../Views/mis/importLabel/ImportExcelOP/importExcelOP";
import ImportExcelParcelPickUp from "../Views/mis/importLabel/ImportExcelParcelPickUp/importExcelParcelPickUp";

const Routes = [
  {
    path: "/",
    role: 0,
    layout: PublicLayout,
    children: [
      { path: "", element: Tracking, protect: 0 }
    ],
    protect: 0,
  },
  {
    path: "/",
    role: 0,
    layout: PublicLayout,
    children: [{ path: "tracking", element: Tracking, protect: 0 }],
    protect: 0,
  },
  {
    path: "/mis/",
    role: 0,
    layout: LoginLayout,
    children: [{ path: "login", element: Login, protect: 0 }],
    protect: 0,
  },
  {
    path: "/mis/",
    layout: misLayout,
    role: 0,
    children: [
      { path: "MGBarcodes", element: MGBarcodes, protect: 1 },
      { path: "ImportExcel", element: ImportExcel, protect: 1 },
      { path: "ImportExcelOP", element: ImportExcelOP, protect: 1 },
      { path: "ImportExcelParcelPickUp", element: ImportExcelParcelPickUp, protect: 1 },
      { path: "Parcel", element: Parcel, protect: 1 },
    ],
    protect: 1,
  },
  {
    path: "/mis/",
    role: 0,
    layout: PublicLayout,
    children: [{ path: "Login", element: Login, protect: 0 }],
    protect: 0,
  },
  {
    path: "/",
    role: 0,
    layout: PublicLayout,
    children: [{ path: "*", element: Error, protect: 0 }],
    protect: 0,
  },
];
export default Routes;
