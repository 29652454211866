import * as ExcelJS from "exceljs";
import axios from "../Helper/axios";
/**
 *
 * @param ref
 */
export const tableToExcel = (ref, filename, exportFinished = () => {}) => {
    let tableData = ref.current.outerHTML;
    tableData = tableData.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
    tableData = tableData.replace(/<input[^>]*>|<\/input>/gi, ""); //remove input params
    let a = document.createElement('a');
    a.href = `data:application/vnd.ms-excel, ${encodeURIComponent(tableData)}`
    a.download = `${filename}.xls`;
    a.click();
    exportFinished();
};

/**
 *
 * @param data
 * @param metaData
 * @param renderers
 * @param filename
 * @param sheetName
 */
export const dataToExcel = (data, metaData,renderers, filename, sheetName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    const PromiseArray = [];
};
export const addImage = async (url, workbook, worksheet, col, row) => {
    try {
        const host = window.location.hostname;
        const currentUrl = host === 'localhost' ? ('https://circumvent-cors.herokuapp.com/' + url ): url;
        const data = await axios.get(currentUrl, {
            responseType: 'blob',
        });
        const blob = data.data;
        return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = () => {
                    const base64data = reader.result; 
                    row.height = 125;
                    try {
                        const image = workbook.addImage({
                                base64: base64data,
                                extension: 'jpeg',
                        });
                        console.log({url, base64data, blob});
                        worksheet.addImage(image, {
                            tl: { col, row: row.number - 1 },
                            ext: { width: 125, height: 125},
                        });    
                        console.log('add image catch');
                        resolve(base64data);
                    }
                    catch (error) {
                       console.log('image fetch error');
                    }
            }
            reader.onerror = (error) => {
                console.error(error);
            };
        });
    }
    catch (error) {
        console.error(error);
    }
};
