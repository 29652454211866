import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import logoNavbar from '../../Assets/images/logo/logoNavbar.png';
import newLogoNavbar from '../../Assets/images/logo/newLogoNavbar.png';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { CHANGE_LANGUAGE } from '../../actions/authen';
import { withTranslation } from 'react-i18next';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathnames: this.props.history.location.pathname,
    };
  }

  handleClick(lang) {
    this.props.CHANGE_LANGUAGE(lang);
    this.props.i18n.changeLanguage(lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.history.pathname !== this.props.history.location.pathname) {
      this.setState({ pathname: nextProps.history.pathname });
    }
  }

  render() {
    document.body.style.backgroundColor = null;
    return (
      <>
        <div id="containerTop" className="px-0 justify-content-end" style={{ display: 'flex', backgroundColor: 'rgb(231, 244, 255)', paddingTop: '1.5%' }}>
          {/* <div style={{ marginRight: '30px' }}>
            <a href="Tracking">
              {' '}
              <Icon.CurrencyDollar />
                {this.props.lan.language === 'th' ? ' 价格估算 ' : ' Price Estimation '}
            </a>
          </div>
          <div style={{ marginRight: '30px' }}>
            <a href="PriceEstimation">
              {' '}
              <Icon.CurrencyDollar />
                {this.props.lan.language === 'th' ? ' 价格估算 ' : ' Price Estimation '}
            </a>
          </div> */}
          {/* <div style={{ marginRight: '30px' }}>
            <a href="ContactUS">
              {' '}
              <Icon.Envelope />
              {this.props.lan.language === 'th' ? ' ติดต่อเรา ' : ' Contact Us  '}
            </a>
          </div> */}
          {/* <div style={{ marginRight: '30px' }}>
            <a href="login">
              {' '}
              <Icon.FilePerson />
              {this.props.lan.language === 'th' ? ' ล็อคอิน ' : ' login '}
            </a>
          </div> */}
          <div style={{ marginRight: '5%' }}>
            <Icon.Globe />
            <a
              href="#th"
              onClick={() => {
                this.handleClick('th');
              }}
            >
              CH
            </a>{' '}
            <span>|</span>{' '}
            <a
              href="#en"
              onClick={() => {
                this.handleClick('en');
              }}
            >
              EN
            </a>{' '}
          </div>
        </div>

        <div
        style={{backgroundColor: "rgb(231, 244, 255)", padding: "10px" }}>
            <hr style={{ marginBlockStart: "0", marginBlockEnd: "0", marginTop: "0", marginBottom: "0", width: "85%", borderTop: "2px solid rgba(25,59,107,.8)"}}/>
        </div> 
        <Navbar collapseOnSelect expand="lg" className="dg-white-aliceblue justify-content-center py-0.5" sticky="top">
          <Navbar.Brand href="/" className="mr-100 justify-content-center">
            <img alt="" src={newLogoNavbar} width="140" className="d-inline-block align-top" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-remove-flex-grow ">
            <Nav className="mr-auto">
              <NavLink to="Tracking" className="nav-link">
                {this.props.lan.language === 'th' ? '追踪货件' : 'Tracking'}
              </NavLink>
              <NavLink to="PriceEstimation" className="nav-link">
                {this.props.lan.language === 'th' ? '价格估算' : 'Price estimation'}
              </NavLink>
              
              {/* <Button
                variant="primary"
                name="tacking"
                className="radius"
                onClick={(e) => {
                  this.props.history.push('Tracking');
                }}
              > 
                {this.props.lan.language === 'th' ? 'ตรวจสอบสถานะ' : 'Track Shipments'}
              </Button>
              */}
              <div id="containerBottom" style={{ padding: '2%' }}>
                <a href="PriceEstimation">
                  {' '}
                  <Icon.CurrencyDollar />
                  {this.props.lan.language === 'th' ? ' Price Estimation ' : ' Price Estimation '}
                </a>
              </div>
              {/* <div id="containerBottom" style={{ padding: '2%' }}>
                <a href="ContactUS">
                  {' '}
                  <Icon.Envelope />
                  {this.props.lan.language === 'th' ? ' ติดต่อเรา ' : ' Contact Us  '}
                </a>
              </div> */}
              {/* <div id="containerBottom" style={{ padding: '1%' }}>
                <a href="login">
                  {' '}
                  <Icon.FilePerson />
                  {this.props.lan.language === 'th' ? ' ล็อคอิน ' : ' login '}
                </a>
              </div> */}
              {/* <div id="containerBottom" style={{ padding: '1%' }}>
                <Icon.Globe />
                <a
                  href="#th"
                  onClick={() => {
                    this.handleClick('th');
                  }}
                >
                  TH
                </a>{' '}
                <span>|</span>{' '}
                <a
                  href="#en"
                  onClick={() => {
                    this.handleClick('en');
                  }}
                >
                  EN
                </a>{' '}
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.LanguageAction,
});

const mapDispatchToProps = (dispatch) => {
  return {
    CHANGE_LANGUAGE: (language) => dispatch(CHANGE_LANGUAGE(language)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
