import React, { useState, useEffect } from "react";
import logoNavbar from "../../Assets/images/logo/logoNavbar.png";
import { LoginService } from "../../Service/service.Authentication";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../actions/authen";

function Login(Authentication) {
  const history = useHistory();
  const [user, username] = useState("");
  const [pass, password] = useState("");
  const [userType, SetUserType] = useState("1");
  const [setErrorUser, SetErrorUser] = useState("form-control");
  const [setErrorMassUser, SetErrorMassUser] = useState(0);
  const [setErrorPass, SetErrorPass] = useState("form-control");
  const [setErrorMassPass, SetErrorMassPass] = useState(0);
  const [setErrorUserType, SetErrorUserType] = useState(0);
  const [loading, Loading] = useState(0);
  //ทดสอบ
  useEffect(() => {
    document.body.style.backgroundColor = "#193b6b";
    return () => {
      document.body.style.backgroundColor = "#ffff";
    };
  }, []);

  async function Login(value) {
    Loading(1);
    const result = await LoginService(value);
    if (result.statusCode === "001") {
      localStorage.removeItem("is_admin");
      Loading(0);
      document.body.style.backgroundColor = "#ffff";
      Authentication.AUTHEN(
        result.data["staff_code"],
        result.data["user_type"],
        result.data["last_name"],
        result.data["first_name"],
        result.data["alias_name"],
        result.data["phone"],
        result.data["email"]
      );
      Authentication.USERINFO();
      localStorage.setItem('username',result.data["staff_code"]);
      localStorage.setItem('last_name',result.data["last_name"]);
      localStorage.setItem('first_name',result.data["first_name"]);
      localStorage.setItem('alias',result.data["alias_name"]);
      localStorage.setItem('user_type',result.data["user_type"]);
      localStorage.setItem('email',result.data["email"]);
      localStorage.setItem('phone',result.data["phone"]);
      localStorage.setItem('phone',result.data["phone"]);
      localStorage.setItem('location',result.data["location"]);
      localStorage.setItem('is_admin',result.data["is_admin"]);
      
      if (result.data["user_type"] === "1") {
        localStorage.setItem('superkey',"admin");
        history.push("/mis/MGBarcodes");
      }
      else {
        localStorage.removeItem("superkey");
        history.push("/mis/MGBarcodes");
      }
    } else {
      Loading(0);
      swal({
        title: "login error",
        text: "your username/password did not match",
        icon: "warning",
        button: "ok",
        //timer: 1500,
      });
    }
  }

  const handleSubmit = () => {
    SetErrorPass("form-control");
    SetErrorMassPass(0);
    SetErrorUser("form-control");
    SetErrorMassUser(0);
    if (user !== "" && pass !== "" && userType !== "") {
      Login({
        username: user !== "" ? user : "",
        password: pass !== "" ? pass : "",
        userType: userType,
      });
    }
    if (pass === "") {
      SetErrorPass("form-control is-invalid");
      SetErrorMassPass(1);
    }
    if (user === "") {
      SetErrorUser("form-control is-invalid");
      SetErrorMassUser(1);
    }

    if (userType === "") {
      SetErrorUserType(1);
    }
  };

  return (
    <div className="dg-login">
      <div className="container ">
        <div className="row " align="center">
          <div className="container">
            <div className="col-sm-6 px-md-1 mt-1">
              <div
                className="card"
                style={{
                  borderTop: "4px solid #ff9900",
                  boxShadow: "0 4px 8px 0 #131212",
                  transition: "0.3s",
                }}
                align="left"
              >
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="row justify-content-md-center">
                      <div className="col-sm-9 px-md-1 mt-1">
                        <div align="center">
                          <img alt="" src={logoNavbar} width="140" className="d-inline-block align-top" />
                        </div>
                        {/* <h3>เข้าสู่ระบบ</h3> */}
                        <br />
                        <div className="form-group">
                          <label htmlFor="usernamePolor">staff_code/email/phone number</label>
                          <input
                            type="text"
                            name="usernamePolor"
                            id="usernamePolor"
                            onChange={(e) => username(e.target.value)}
                            className={setErrorUser}
                          />
                          {setErrorMassUser === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              please enter your username (staff_code/email/phone number) {" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="passwordPolor">password</label>
                          <input
                            type="password"
                            onChange={(e) => password(e.target.value)}
                            className={setErrorPass}
                            id="passwordPolor"
                            name="passwordPolor"
                          />
                          {setErrorMassPass === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              please enter your password{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group" hidden>
                          <label htmlFor="userType">user_type</label>
                          <div className="row">
                            <div className="col-md-3 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType1"
                                  value="3"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType1">
                                  china employee
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType2"
                                  value="2"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType2">
                                  Madagascar employee
                                </label>
                              </div>
                            </div>
                            <div className="col-md-5 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType3"
                                  value="1"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType3">
                                  admin
                                </label>
                              </div>
                            </div>
                          </div>
                          {setErrorUserType === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              Please choose a user{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>
                        <button
                          disabled={loading === 1 ? true : false}
                          type="submit"
                          className={
                            loading === 1 ? "btn btn-secondary btn-lg btn-block" : "btn btn-primary btn-lg btn-block"
                          }
                        >
                          {loading === 1 ? (
                            <center>
                              <div className="spinner-border" role="status"></div>
                            </center>
                          ) : (
                            "login"
                          )}
                        </button>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AUTHEN: (username,
             roleType,
             fullname,
             province_code,
             amphur_code,
             show_shipment_cost,
             show_address_receiver) => {
      dispatch(AUTHEN(username,
          roleType,
          fullname,
          province_code,
          amphur_code,
          show_shipment_cost,
          show_address_receiver));
    },
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
