import React, { Component } from "react";
import GenberCode from "./genberCode";
import logoLabel from "../../Assets/images/logo/logoLabel.png";
import logo2 from "../../Assets/images/logo/logo2.png";

export default class FormatLabelAddress extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hasNoRefNo = this.props.supplies.ref_no === "0" || this.props.supplies.ref_no === "";
    const hasInvoiceNo = this.props.supplies.invoice !== "" || this.props.supplies.invoice !== null;
    const hasReturnDoc = this.props.supplies.return_doc === "1" || this.props.supplies.return_doc === true;
    const hasNoRefNo2 = this.props.supplies.ref_no2 === null || this.props.supplies.ref_no === "";
    const hasNoItem_desc = this.props.supplies.item_desc === null || this.props.supplies.item_desc === "";
    const hasNoDistrict = this.props.supplies.receiver_district === null || this.props.supplies.receiver_district === "";
    return (
      <div
        style={{
          fontFamily: "Kanit",
          fontSize: "13px",
          position: "relative",
          marginTop: "1px",
          marginBottom: "1px",
        }}
        className="page-break"
      >
        <div style={{ paddingTop: "60px" }}></div>
          <div>ผู้รับ : {this.props.supplies.receiver_name}</div>
          <div>ที่อยู่ : {this.props.supplies.receiver_address}</div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div>
              {/* <b>{"อำเภอ/เขต " + this.props.supplies.receiver_amphur}</b> */}
              {hasNoDistrict ? (
                <b>{"อำเภอ " + this.props.supplies.receiver_amphur}</b>
              ):(
                <b>{ "ตำบล " + this.props.supplies.receiver_district + " อำเภอ " + this.props.supplies.receiver_amphur}</b>
              )}
              
            </div>
            <div>
              <b>{"จังหวัด " + this.props.supplies.receiver_province}</b>
            </div>
          </div>
          <center>{"Tel :" + " " + this.props.supplies.receiver_phone}</center>
          <br/>
          <div>ผู้ส่ง : {this.props.supplies.sender_name}</div>
          <br />
          {/* <center><b style={{ fontSize: "20px"}}>{this.props.supplies.receiver_zipcode}</b></center> */}
        
        <hr style={{ marginTop: "10px" }} />
        <div>
          รหัส : {this.props.supplies.cust_Code}
          <div style={{ paddingTop: "20px"}}>
          {/* <div> */}
            ลําดับ {this.props.supplies.number}
            <center>
              <img alt="" src={logo2} width="120" height="80" className="d-inline-block align-top" />
            </center>
            
            {/* <center>{this.props.supplies.receiver_name}</center>
            {this.props.supplies.deposit_type === "2" ? (
              <center>
                <b>
                  {"(ฝาก " + this.props.supplies.deposit_fullname}
                  {this.props.supplies.deposit_phone !== "" ? "/" : ""}
                  {this.props.supplies.deposit_phone + ")"}
                </b>
              </center>
            ) : this.props.supplies.deposit_type === "3" ? (
              <center>
                <b>
                  {"(ส่งตรงตัว/ฝาก " + this.props.supplies.deposit_fullname}
                  {this.props.supplies.deposit_phone !== "" ? "/" : ""}
                  {this.props.supplies.deposit_phone + ")"}
                </b>
              </center>
            ) : (
              <center>
                <b>{"(ส่งตรงตัว)"}</b>
              </center>
            )} */}
          </div>
          <div style={{ textAlign: "center" }} id={"img" + this.props.supplies.number}>
              <GenberCode awbCode={this.props.supplies.awb_code} />
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {localStorage.getItem('superkey') !== 'shop' ? (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {!hasNoRefNo && <span>{this.props.supplies.ref_no}</span>}
            {hasInvoiceNo && <span>{this.props.supplies.invoice}</span> }
            {hasReturnDoc && <span>{this.props.supplies.num_return_doc}</span>}
            {!hasNoRefNo2 && <span>{this.props.supplies.ref_no2}</span>}
            {!hasNoItem_desc && <span>{this.props.supplies.item_desc}</span>}
            <span>วันที่พิมพ์ {this.props.supplies.date}/{this.props.supplies.month}/{this.props.supplies.year + 543}</span>
          </div>
          ):(
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {!hasNoRefNo && <span>{this.props.supplies.ref_no}</span>}
            <span>วันที่พิมพ์ {this.props.supplies.date}/{this.props.supplies.month}/{this.props.supplies.year + 543}</span>
          </div>
          )}
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <span>Kt./Kg.{" "}
                {this.props.supplies.weight != null
                  ? this.props.supplies.weight
                  : "-"}
              </span>
              {/* <br/> */}
              {/* <span> <img alt="" src={logoLabel} width="80" height="30" className="d-inline-block align-top" /> </span> */}
              {/* &nbsp;&nbsp; */}
              {this.props.supplies.receiver_name}
              {this.props.supplies.deposit_type === "2" ? (
                  <b>
                    {"(ฝาก " + this.props.supplies.deposit_fullname}
                    {this.props.supplies.deposit_phone !== "" ? "/" : ""}
                    {this.props.supplies.deposit_phone + ")"}
                  </b>
              ) : this.props.supplies.deposit_type === "3" ? (
                  <b>
                    {"(ส่งตรงตัว/ฝาก " + this.props.supplies.deposit_fullname}
                    {this.props.supplies.deposit_phone !== "" ? "/" : ""}
                    {this.props.supplies.deposit_phone + ")"}
                  </b>
              ) : (
                  <b>{"(ส่งตรงตัว)"}</b>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
