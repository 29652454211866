import React from "react";
import Head from "./Header";
import Footer from "./Footer";

export default function PublicLayout(props) {
  return (
    <>
      {/* <Head {...props} /> */}
      <div
        style={{ minHeight: "calc(100vh - 200px)" }}
      >
        {props.children}
      </div>
      {/* <Footer {...props} /> */}
    </>
  );
}
