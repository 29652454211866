import React, { useState , useEffect} from 'react';
import DateTH from '../../../Helper/date-th';
import printJS from 'print-js';
import LabelFormMuti from './LabelFormMuti';
import ModalsShopCode from './ModalsShopCode';
import ModalsStatus from './ModalsStatus';
import ModalsMG from './ModalsMG';
import ModalsHistory from './ModalsHistory';
import ModalsReceivedImg from './ModalsReceivedImg';
import ModalImage from './ModalImage';
import { nanoid } from 'nanoid';
import Pagination from '@material-ui/lab/Pagination';
import {
  BarcodeUpdateService,
  CODUpdateService,
  UpdateCustService,
  UploadImage,
  HistoryParcel,
  deliverImage,
  getIndividualConoteData,
  getMultipleConoteData,
  deliverFailImgService, deleteDeliveryImage, scanStatusCheck
} from '../../../Service/service.Barcode';
import swal from 'sweetalert';
import {ManageBarCodeExcelExport} from "./ManageBarCodeExcelExport";
import {ExportWithoutPictures} from "./ExportWithoutPictures";
import * as Icon from "react-bootstrap-icons";
import {ExportProgress} from "../../../Components/ExportProgress/ExportProgress";
import {ExportProgressStatus} from "../../../enums/ExportProgressStatus";
import {swalError, swalSuccess} from "../../../Components/sweetAlert";
import MultipleEditModal from './MultipleEditModal';
import EditRefNo from './EditRefNo';
import RemarkHistory from './RemarkHistory';
import {barcodeHasComputedErrors, getEmptyBarcodeParcels} from "./utils/MgBarcodes.utils";
import {MgBarcodeErrorList} from "./UI/MgBarcodeErrorList";
import {MgBarcodeUpload} from "./UI/MgBarcodeUpload";
import { useHistory } from "react-router-dom";

//TOUCH
function ShowData({
  data,
  setData,
  setPageSizes,
  pageSizes,
  values,
  fetchData,
  page,
  currentPage,
  setCurrentPage,
  sum,
  dataSelect,
  setDataSelect,
  printLabel,
  setPrintLabel,
  printLabelSingle,
  setPrintLabelSingle,
  fetchExportData,
  fetchExportData2,
  exportData,
  exportData2,
  clearExportData,
  isExporting,
}) {
  const [modalShopCode, setModalShopCode] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalMG, setModalMG] = useState(false);
  const [modalHis, setModalHis] = useState(false);
  const [modalReceived, setModalReceived] = useState(false);
  const [modalImg, setModalImg] = useState(false);
  const [notImg, setNotImg] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [deliverData, setDeliverData] = useState([]);
  const [deliverDataId, setDeliverDataId] = useState(null);
  const [conoteData, setConoteData] = useState(null);
  const [failImgData, setFailImgData] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateRef_no, setShowUpdateRef_no] = useState(false);
  const [currentRemarkHistory, setCurrentRemarkHistory] = useState(null);
  const [barcodeErrorData, setBarcodeErrorData] = useState(null);
  const [barcodeReceivedData, setBarcodeReceivedData] = useState(null);
  const history = useHistory();
  const checked = () => {
    dataSelect.map((item, i) => {
      if (document.getElementById('flexCheckDefault' + item.code) != null) {
        document.getElementById('flexCheckDefault' + item.code).checked = true;
      }
    });
  };

  const initExportData = async () => {
    fetchExportData({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
    });
  };

  const initExportData2 = async () => {
    fetchExportData2({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      page: 1,
      number_list: pageSizes,
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
    });
  };

  async function addPrints(value) {
    if (printLabel.length === 0 || printLabel.filter((a) => a.awb_code === value.awb_code).length === 0) {
        let data = [...printLabel, { ...value }];
        setPrintLabel(data);
    } else {
        setPrintLabel([...data.filter((a) => a.awb_code !== value.awb_code)]);
    }
  }

  async function deliveryFailImg(awb_code,ref_no){
    //setFailImgData([]);
    const res = await deliverFailImgService({
      awb_code: awb_code
    });
    if (res !== undefined) {
      if (res.length > 0) {
        //alert(JSON.stringify(res));
        setFailImgData(res);
      
      }
      else{
        const res2 = await deliverFailImgService({
          awb_code: ref_no
        }); 

        if (res2 !== undefined) {
          if (res2.length > 0) {
            //alert(JSON.stringify(res));
            setFailImgData(res2);
          
          }
          else{
            setFailImgData([]);
          }
        }
        else{
          setFailImgData([]);
        }
      }
    }
    else{
      setFailImgData([]);
    }
  }

  function dataArray(data) {
    let check = document.getElementById('flexCheckDefault' + data.code).checked;
    if (check) {
      setDataSelect((t) =>
        t.concat({
          code: data.code,
          awb_code: data.tracking_number,
          cust_code: localStorage.getItem('username'),
          shipment_status: '',
          receiver_province: data.receiver_province,
          ref_no: data.ref_no,
          invoice: data.invoice,
          height: data.box_height,
          length: data.box_length,
          size: data.box_size,
          width: data.box_width,
          weight: data.weight,
          receiver_amphur: data.receiver_amphur,
          cod_cost: data.cod_cost
        }),
      );
    } else {
      let array = dataSelect;
      setDataSelect([]);
      array.map((row) => {
        if (row.code !== data.code) {
          setDataSelect((t) =>
            t.concat({
              code: row.code,
              caca:"1",
              awb_code: row.tracking_number,
              cust_code: localStorage.getItem('username'),
              shipment_status: '',
            }),
          );
        }
      });
    }
  }
  function dataArrayAll(check) {
    setDataSelect([]);
    setPrintLabel([]);
    if (check) {
      data
        .filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined)
        .map((item) => {
          if (document.getElementById('flexCheckDefault' + item.code) != null) {
            document.getElementById('flexCheckDefault' + item.code).checked = true;
          }
          setDataSelect((t) =>
            t.concat({
              code: item.code,
              caca:"ca",
              awb_code: item.tracking_number,
              cust_code: localStorage.getItem('username'),
              shipment_status: '',
            }),
          );
          setPrintLabel((t) => t.concat(item));
        });
    } else {
      data.map((item) => {
        if (document.getElementById('flexCheckDefault' + item.code) != null) {
          document.getElementById('flexCheckDefault' + item.code).checked = false;
        }
      });
    }
  }

  async function UpdateBarcode(value) {
    const res = await BarcodeUpdateService(value);
    if (res.status === 1) {
      swal({
        title: 'success',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ok',
        timer: 1500,
      });
      setDataSelect([]);
      setPrintLabel([]);
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
      });
    } else {
      swal({
        title: 'An error occured',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'close',
        timer: 1500,
      });
    }
  }

  async function UpdateCOD(value) {
    const res = await CODUpdateService(value);
    if (res.status === 1) {
      swal({
        title: 'สำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
      });
    } else {
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function UpdateCust(value) {
    const res = await UpdateCustService(value);
    if (res.status === 1) {
      swal({
        title: 'สำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
      });
    } else {
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function Upload(value) {
    const res = await UploadImage(value);
    if (res.status) {
      swal({
        title: res.message,
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
      });
    } else {
      swal({
        title: res.message,
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function History(value) {
    setHistoryData([]);
    const res = await HistoryParcel(value);
    if (res !== undefined) {
      if (res.length > 0) {
        setHistoryData(res);
      }
    }
  }

  async function Deliver(value) {
    setDeliverData([]);
    setDeliverDataId(value.id);
    const res = await deliverImage(value);
    if (res !== undefined) {
      if (res.length > 0) {
        setDeliverData(res);
      }
    }
  }

  function refresh(){
    setPrintLabelSingle(null);
    setData([]);
    fetchData({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      page: currentPage,
      number_list: pageSizes,
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
    });
  }

  const scanToReceived = async () => {
      try{
        const result = await scanStatusCheck(dataSelect.map(row => row.awb_code), '5');
        if(barcodeHasComputedErrors(result.data.data)){
          setBarcodeErrorData(result.data.data);
          return;
        }
        setBarcodeReceivedData(result.data.data);
      }
      catch(error){
        if (error.response?.status === 451) {
          setBarcodeErrorData(error.response?.data?.data || null);
        } else {
          swalError({
            text: (error.response?.data?.message || error.message),
          })
        }
      }
  };

  const deleteImage = async ({parcelId,imageId,awb_code}) => {
    try {
      const result = await deleteDeliveryImage(parcelId,imageId);
      console.log({
        result,
      });
      swalSuccess({
        text: 'ลบภาพแล้ว',
      });
      Deliver({
        id: parcelId,
        awb_code
      });
      refresh();
    }
    catch(error){
      console.error(error);
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }
  return (
    <>
      {/* {exportData && <ManageBarCodeExcelExport
          onExportBeginning={(value) => setExportProgression({
            ...exportProgression,
            total: value,
          })}
          onExportEvolving={(progress) => {setExportProgression(progress)}}
          exportData={exportData}
          exportFinished={clearExportData} /> } */}
      {barcodeReceivedData && <MgBarcodeUpload onBarcodeErrors={(data) => {
        setBarcodeReceivedData(null);
        setBarcodeErrorData(data);
      }} selectData={dataSelect} onHide={() => setBarcodeReceivedData(null)} />}
      {barcodeErrorData && <MgBarcodeErrorList
          validationData={barcodeErrorData}
          onHide={() => setBarcodeErrorData(null)}
      />}
      {exportData2 && <ExportWithoutPictures exportData2={exportData2} />}
      {currentRemarkHistory && <RemarkHistory parcel_id={currentRemarkHistory} onClose={() => {
        setCurrentRemarkHistory(null);
      }}  />}
      {/** MODAL FORM CHANGE CUSTOMER */}
      <ModalsShopCode data={data} printLabelSingle={printLabelSingle} dataSelect={dataSelect} UpdateCust={UpdateCust} modalShopCode={modalShopCode} setModalShopCode={setModalShopCode} />
      {/** MODAL FORM CHANGE STATUS */}
      <ModalsStatus data={data} printLabelSingle={printLabelSingle} dataSelect={dataSelect} UpdateBarcode={UpdateBarcode} modalStatus={modalStatus} setModalStatus={setModalStatus} />
      {/** MODAL FORM MANGER BARCODE */}
      {printLabelSingle && <ModalsMG
        Upload={Upload}
        notImg={notImg}
        setNotImg={setNotImg}
        data={data}
        printLabelSingle={printLabelSingle}
        setPrintLabelSingle={setPrintLabelSingle}
        dataSelect={dataSelect}
        UpdateBarcode={UpdateBarcode}
        UpdateCOD={UpdateCOD}
        modalMG={modalMG}
        setModalMG={setModalMG}
        finishUpdate={refresh}
      />}
      <ModalsHistory modalHis={modalHis} setModalHis={setModalHis} historyData={historyData} />
      {deliverData && <ModalsReceivedImg onDelete={(image) => deleteImage({
        parcelId: deliverDataId,
        imageId: image.id,
        awb_code: image.awb_code
      })}
      modalReceived={modalReceived}
      setModalReceived={setModalReceived}
      deliverData={deliverData} />}
      <ModalImage modalImg={modalImg} setModalImg={setModalImg} failImgData={failImgData} setFailImgData={setFailImgData} />
      <LabelFormMuti printLabel={printLabel} />
      <div className="row text-center">
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          Total : {page.total.toLocaleString('en')} parcels
        </div>
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          Weight : {sum.weight === null ? 0 : sum.weight} KG
        </div>
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          CBM : {sum.cbm === null ? 0 : sum.cbm} cbm
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between mb-1">
        <div>
        {/* {localStorage.getItem("superkey")==="admin" ? (
          {/* <span className="mr-1">
            <button
              type="button"
              style={{ width: '150px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={(e) => {
                setModalShopCode(true);
              }}
            >
              เปลี่ยนรหัสลูกค้า
            </button>
          </span> }
        ):""} */}
          {/* <span className="mr-1">
            <button
              type="button"
              style={{ width: '200px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={(e) => {
                setModalStatus(true);
              }}
            >
              อัพเดทสถานะหลายรายการ
            </button>
          </span> */}
          {/* <span className="mr-1">
            <button
              type="button"
              style={{ width: '150px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-warning mb-1'}
              onClick={() => {
                printJS({
                  printable: 'labelNormal',
                  type: 'html',
                  scanStyles: false,
                  css: [
                    window.location.protocol + '//' + window.location.host + '/static/PrinterCOD.css',
                    'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                  ],
                  style: '@page {size: 10cm 7cm;margin: 0cm;}',
                });
              }}
            >
              พิมพ์ลาเบลเป็นชุด
            </button>
            labelNormal
          </span> */}
          {/* <span className="mr-1">
            <button
              type="button"
              style={{ width: '200px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}
              onClick={() => {
                printJS({
                  printable: 'labelAddress',
                  type: 'html',
                  scanStyles: false,
                  targetStyles: ['*'],
                  css: [
                    window.location.protocol + '//' + window.location.host + '/static/Printer.css',
                    'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                  ],
                  style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                });
              }}
            >
              พิมพ์ลาเบล/ที่อยู่เป็นชุด
            </button>
            labelAddress
          </span>
          <span className="mr-1">
            <button
                type="button"
                onClick={() => {
                  const ref_no_set = new Set(printLabel.map(row => row.ref_no));
                  getMultipleConoteData([...ref_no_set])
                      .then(result => {
                          console.log("result =", result);
                          const conoteData = result?.data?.data;
                          const groupData = result?.data?.groupData;
                          conoteData?.forEach((row) => {
                            row.groupData = groupData.filter(group => row.ref_no === group.ref_no);
                          });
                          if(!conoteData){
                            swalError({
                              title: 'เกิดข้อผิดพลาด',
                              text: 'ไม่สามารถเรียกข้อมูลบันทึกร่วมได้',
                            });
                            return;
                          }
                          console.log(conoteData);
                          setConoteData(conoteData);
                      }, error => {
                        console.log('error =', error);
                        swalError({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'ไม่สามารถเรียกข้อมูลบันทึกร่วมได้',
                        });
                      });
                }}
                disabled={printLabel.length === 0}
                className={printLabel.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}>
              ปริ้นco note หลายใบ
            </button>
          </span>
          <span className="mr-1">
            <button
              type="button"
              onClick={() => setShowUpdate(true)}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}>
                <Icon.Pen /> 
                show update
            </button>
          </span>
          <span className="mr-1">
            <button
              type="button"
              onClick={() => setShowUpdateRef_no(true)}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}>
                <Icon.Pen /> 
                Update ref_no
            </button>
          </span> */}
          {/* {localStorage.getItem("superkey")==="admin" ? (
            <span className="mr-1">
              <button
                  type="button"
                  onClick={() => scanToReceived()}
                  disabled={dataSelect.length === 0 ? true : false}
                  className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}>
                  <Icon.Pen /> 
                Scan to Received
              </button>
            </span>
          ):""} */}
          {/* <span className="mr-1">
            <button
            type="button"
            // style={{ width: '100px' }}
            className="btn btn btn-info mb-1"
            onClick={initExportData2}>
              <Icon.FileEarmarkArrowUp /> export
            </button>
          </span>
          <span className="mr-1">
            <button
            type="button"
            // style={{ width: '100px' }}
            className="btn btn btn-info mb-1"
            onClick={initExportData}>
              <Icon.FileEarmarkArrowUp /> export/pic
            </button>
          </span> */}
        </div>
        <div className="col-sm-1">
          <select
            value={pageSizes}
            className="form-control mb-1"
            aria-label="Default select example"
            onChange={(e) => {
              setPageSizes(e.target.value);
              setDataSelect([]);
              setPrintLabel([]);
              setConoteData([]);
              setCurrentPage(1);
              fetchData({
                search: values.search,
                shipment_status: values.shipment_status,
                start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                page: 1,
                number_list: e.target.value,
                cust_code: values.brand,
                cod_status: values.cod_status,
                show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div style={{ overflow: 'auto' }}>
        <table className="table table-bordered">
          <thead style={{ backgroundColor: '#3f75c1' }}>
            <tr style={{ color: '#FFFFFF' }}>
              <td></td>
              <td style={{ width: '7%' }}>Created date</td>
              <td style={{ width: '8%' }}>Tracking number</td>
              <td style={{ width: '6%' }}>Recipient</td>
              <td style={{ width: '8%' }}>Type</td>
              <td style={{ width: '18%' }}>Description</td>
              <td style={{ width: '18%' }}>Dimensions/weight</td>
              <td style={{ width: '10%' }}>Shipment status</td>
              <td style={{ width: '11%' }}></td>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index + 'Key' + (value.awb_code !== null ? value.awb_code : value.ref_no)}>
                <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                <td>
                  {value.created_date}
                </td>
                {/* <td>{value.cust_code}</td> */}
                <td>
                  <b
                    type="button"
                    onClick={() => {
                      setModalHis(true);
                      //Ataoko eo amn ref_no ny code (ID_PARCELS) fa kamo be za
                      History({ awb_code: value.tracking_number, ref_no: value.code});
                    }}
                  >  {value.tracking_number === null || value.tracking_number === "" ? " ":
                   value.tracking_number}  
                  </b>
                  <hr/>
                </td>
                <td>{value.receiver_name}
                <hr/>
                <p>{value.receiver_phone}</p>
                </td>
                <td>
                  <p>{value.type_name}</p>
                  <hr/>
                  <p>file source : {value.fileSource}</p>
                </td>
                <td>
                  <p>{value.description}</p>
                {value.remark === null || value.remark === "" ? " ": ( <p style={{color:'red'}}>remark : {value.remark}</p> )} 
                </td>              
                <td>
                  {value.box_length+" x "+value.box_width+" x "+value.box_height+" : "+value.cbm} cbm <hr/> 
                  {value.weight} Kg</td>
                <td style={{ textAlign: 'center' }}>
                    {value.shipment_name}
                </td>                
                <td align="center" style={{ width: '170px' }}>
                  <div className="d-flex flex-column align-items-center justify-content-center" style={{gap: '5px'}}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={(e) => {
                              setModalMG(true);
                              setPrintLabelSingle(value);
                              // alert(JSON.stringify(value));
                              setDataSelect([
                                {
                                  code: value.code,
                                  awb_code: value.tracking_number,
                                  cust_code: localStorage.getItem('username'),
                                }
                              ])
                              setNotImg(true);
                            }}
                        >
                          manage
                        </button>
                      </div>
                </td>
                {data.length === index + 1 ? checked() : ''}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {parseInt(page.total).toLocaleString('en')} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={currentPage}
            onChange={(e, newPage) => {
              setCurrentPage(newPage);
              fetchData({
                search: values.search,
                shipment_status: values.shipment_status,
                start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                page: newPage,
                number_list: pageSizes,
                cust_code: values.brand,
                cod_status: values.cod_status,
                show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
              });
            }}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      {showUpdate && dataSelect && <MultipleEditModal barcodes={dataSelect} onFinish={() => {
          setShowUpdate(false);
          setDataSelect([]);
          refresh();
        }
      }
        onClose={() => setShowUpdate(false)}
      />} 
      {showUpdateRef_no && dataSelect && <EditRefNo barcodes={dataSelect} onFinish={() => {
          setShowUpdateRef_no(false);
          setDataSelect([]);
          refresh();
        }
      }
        onClose={() => setShowUpdateRef_no(false)}
      />}
    </>
  );
}

export default ShowData;
