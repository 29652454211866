import * as Yup from "yup";

const Schema = Yup.object().shape({
    tracking_number: Yup.string().required("veuillez entrer un numéro de suivi valide"),
    startYear: Yup.string().required("please enter a starting Year"),
    startMonth: Yup.string().required("please enter a starting Month"),
    startDay: Yup.string().required("please enter a starting Day"),
    endYear: Yup.string().required("please enter an ending Year"),
    endMonth: Yup.string().required("please enter a ending Month"),
    endDay: Yup.string().required("please enter a ending Day"),
});

export default Schema;