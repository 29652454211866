import React from 'react';
import { nanoid } from 'nanoid';
import FormatLabel from '../formatLabel';
import FormatLabelCod from '../formatLabelCod';
import FormatLabelAddress from '../formatLabelAddress';
import FormatLabelAddressCod from '../formatLabelAddressCod';
import FormatLabelPornor from '../formatLabelPornor/formatLabelPornor';
import FormatLabelAddressPornor from '../formatLabelPornor/formatLabelAddressPornor';

function LabelFormSingle({ awbCode, data }) {
  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();

  return (
    <div>
      <>
        <div style={{ display: 'none' }}>
          <div id="labelNormalSing">
            {data
              .filter((x) => x.awb_code === awbCode)
              .map((value, index) => (
                ////ใช้สำหรับพิมพ์ลาเบลเป็นชุด
                <div key={nanoid()}>
                  {value.platform === '5' ? (
                    <FormatLabelPornor
                      key={nanoid()}
                      supplies={{
                        cust_code: value.cust_code,
                        /////////////////
                        cod_cost: value.cod_cost,
                        /////////////////
                        awb_code: value.awb_code,
                      }}
                    />
                  ) : value.cod_cost === '0' ? (
                    <FormatLabel
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                        return_doc: value.return_doc,
                        num_return_doc: value.num_return_doc,
                        ref_no2: value.ref_no2,
                        receiver_zipcode: value.receiver_zipcode,
                        item_desc: value.item_desc,
                      }}
                    />
                  ) : (
                    <FormatLabelCod
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                      return_doc: value.return_doc,
                      num_return_doc: value.num_return_doc,
                      ref_no2: value.ref_no2,
                      receiver_zipcode: value.receiver_zipcode,
                      item_desc: value.item_desc,
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </>
      <>
        <div className="contentPrint2" style={{ display: 'none' }}>
          <div id="labelAddressSing">
            {data
              .filter((x) => x.awb_code === awbCode)
              .map(
                (
                  value,
                  index, ////ใช้สำหรับพิมพ์ลาเบล/ที่อยู่เป็นชุด
                ) => (
                  <div key={nanoid()}>
                    {value.platform === '5' ? (
                      <FormatLabelAddressPornor
                        key={nanoid()}
                        supplies={{
                          cust_code: value.cust_code,
                          /////////////////
                          receiver_name: value.receiver_name,
                          receiver_phone: value.receiver_phone,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_zipcode: value.receiver_zipcode,
                          /////////////////
                          sender_name: value.sender_name,
                          sender_phone: value.sender_phone,
                          sender_address: value.sender_address,
                          sender_amphoe: value.sender_amphoe,
                          sender_province: value.sender_province,
                          sender_zipcode: value.sender_zipcode,
                          /////////////////
                          cod_cost: value.cod_cost,
                          /////////////////
                          awb_code: value.awb_code,
                          weight: value.weight,
                          ref_no: value.ref_no,
                          invoice: value.invoice,
                          return_doc: value.return_doc,
                          num_return_doc: value.num_return_doc,
                          ref_no2: value.ref_no2,
                          item_desc: value.item_desc,
                      
                        }}
                      />
                    ) : value.cod_cost === '0' ? (
                      <FormatLabelAddress
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: index + 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                          ref_no: value.ref_no,
                          invoice: value.invoice,
                          return_doc: value.return_doc,
                          num_return_doc: value.num_return_doc,
                          ref_no2: value.ref_no2,
                          receiver_zipcode: value.receiver_zipcode,
                          item_desc: value.item_desc,
                        }}
                      />
                    ) : (
                      <FormatLabelAddressCod
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: index + 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                          ref_no: value.ref_no,
                          invoice: value.invoice,
                          return_doc: value.return_doc,
                          num_return_doc: value.num_return_doc,
                          ref_no2: value.ref_no2,
                          receiver_zipcode: value.receiver_zipcode,
                          item_desc: value.item_desc,
                        }}
                      />
                    )}
                  </div>
                ),
              )}
          </div>
        </div>
      </>
    </div>
  );
}

export default LabelFormSingle;
