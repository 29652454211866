import React, { Fragment } from 'react';
import Asset from '../../../Assets/images/logo/Asset.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Carousel from "react-bootstrap/Carousel";

function TrackingDetailEN({ value }) {
  function Date(params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.created_date.substring(0,10) : '';
  }
  function Name(params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.name : '';
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <div className="rt-container container">
            <div className="col-rt-12">
              <div className="Scriptcontent">
                <Fragment>
                  <div className={'step-vt ' + (value.shipment_name === "RECEIVED IN CHINA WAREHOUSE" ? 'step-active-vt' : '')}>
                    <div>
                      <div className="circle">1</div>
                    </div>
                    <div>
                      <div className="title text-front">REÇU ENTREPÔT CHINE</div>
                      <div className="caption text-front">
                        {Date('RECEIVED IN CHINA WAREHOUSE') === '' ? value.created_date.substring(0,10) : Date('RECEIVED IN CHINA WAREHOUSE')}
                      </div>
                    </div>
                  </div>
                  {value.shipment_name === "IN TRANSIT" || Date('IN TRANSIT') !== '' 
                  || value.shipment_name === 'ARRIVED IN MADAGASCAR'? (
                    <div className={'step-vt ' + (value.shipment_name === 'IN TRANSIT' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">2</div>
                      </div>
                      <div>
                        <div className="title text-front">EN TRANSIT</div>
                        <div className='caption text-front'>
                            {value.stockin_date.substring(0,10)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === 'RETURNED TO CHINA' || Date('RETURNED TO CHINA') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'RETURNED TO CHINA' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-front">RETOURNÉ EN CHINE</div>
                        <div className={Date('RETURNED TO CHINA') === '' ? 'hidden-ct mt-5' : 'caption text-front'}>
                          {Date('RETURNED TO CHINA')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                 
                  {value.shipment_name === 'ARRIVED IN MADAGASCAR' || Date('ARRIVED IN MADAGASCAR') !== ''? (
                    <div className={'step-vt ' + (value.shipment_name === 'ARRIVED IN MADAGASCAR' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">3</div>
                      </div>
                      <div>
                        <div className="title text-front">ARRIVÉ À MADAGASCAR</div>
                        <div className='caption text-front'>
                            {value.receiver_date.substring(0,10)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackingDetailEN;
